import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId70205: SiteIdOverrideConfig = {
  car: {
    disableVendorCouponCode: true,
    iHaveDiscount: "wizard.car.having.brand.title",
    vendors: {
      AC: {
        includeInDropdown: false,
      },
      AD: {
        includeInDropdown: false,
      },
      EY: {
        includeInDropdown: false,
      },
      EP: {
        includeInDropdown: false,
      },
      FX: {
        includeInDropdown: false,
      },
      MW: {
        includeInDropdown: false,
      },
      NU: {
        includeInDropdown: false,
      },
      ZA: {
        includeInDropdown: false,
      },
      RO: {
        includeInDropdown: false,
      },
      SX: {
        includeInDropdown: false,
      },
      SV: {
        includeInDropdown: false,
      },
      SC: {
        includeInDropdown: false,
      },
    },
  },
};
