import { HotelConfig } from "stores/wizard/config";
import { hotelConfig } from "./hotelConfig";

export const hotelConfigHTG: HotelConfig = {
  ...hotelConfig,
  location: {
    ...hotelConfig.location,
    destination: {
      ...hotelConfig.location.destination,
      queryParams: {
        regionId: "",
        latLong: "latLong",
      },
    },
  },
};
