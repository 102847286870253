import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId70129: SiteIdOverrideConfig = {
  global: {
    includeSingleLOBsWithinPackages: false,
  },
  flight: {
    heading: {
      locTitleToken: "wizard.wotif.flights.title",
    },
  },
  package: {
    heading: {
      locTitleToken: "wizard.wotif.packages.title",
    },
    lobLabels: {
      hotelsLabel: "wizard.wotif.hotels.tab.label",
      hotelsEnhancedLabel: "wizard.comet.packages.accommodationSelected.pill.text",
      hotelsEnhancedVariantLabel: "wizard.comet.packages.accommodation.pill.text.variant",
    },
    showEnhancedLabels: true,
  },
  hotel: {
    heading: {
      locTitleToken: "wizard.wotif.hotels.title",
    },
    navigation: {
      tabTitleToken: "wizard.wotif.hotels.tab.label",
    },
  },
  lx: {
    heading: {
      locTitleToken: "wizard.wotif.activities.title",
    },
  },
  car: {
    date: {
      maxDaysRange: 329,
    },
    heading: {
      locTitleToken: "wizard.wotif.cars.title",
    },
    navigation: {
      tabTitleToken: "wizard.wotif.label.Cars",
    },
  },
};
