export interface GlobalConfig {
  navigation: {
    defaultLob: string;
    activeLob: string;
  };
  date: {
    invalidDateRangeEndBeforeStart: string;
  };
  packageWith3PP: boolean;
  heroImageSrc: string;
  defaultDestination?: string;
  defaultMultipleDestination?: string[];
  destinationDropdownTypeahead?: boolean;
  defaultOrigin?: string;
  defaultMultipleOrigin?: string[];
  selectedProduct?: string;
  halfWizard: boolean;
  calendarFilterCarrierCode?: string;
  showHeroMobile?: boolean;
  collapsed: boolean;
  cobrandImageUrl?: string;
  displayBiasedWizard?: boolean;
  includeSingleLOBsWithinPackages?: boolean;
  defaultStopover?: string;
  defaultMultipleStopover?: string[];
  userLocation?: string;
  originType?: string;
  destiantionType?: string;
}

export enum backgroundImagePosition {
  CENTER = "Center",
  INITIAL = "Initial",
  BOTTOM = "Bottom",
}

export enum LocationState {
  BIASED = "biased",
  UNBIASED = "unbiased",
  DEFAULT = "",
}
