import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId80004: SiteIdOverrideConfig = {
  global: {
    includeSingleLOBsWithinPackages: true,
  },
  flight: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.travelocity.label.flight",
      locSubtitleTextToken: "wizard.travelocity.subLabel.flight",
      differentiatedTitles: false,
    },
  },
  package: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.travelocity.label.packages",
      locSubtitleTextToken: "wizard.travelocity.subLabel.packages",
      differentiatedTitles: true,
    },
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.text",
    },
    lobLabels: {
      hotelsLabel: "wizard.hotel.tab.title.label",
      hotelsEnhancedLabel: "wizard.packages.staySelected.pill.text",
      hotelsEnhancedVariantLabel: "wizard.packages.stay.pill.text.variant",
    },
  },
  hotel: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.travelocity.label.hotel",
      locSubtitleTextToken: "wizard.travelocity.subLabel.hotel",
      locTitleToken: "wizard.travelocity.hotels.tab.title",
      differentiatedTitles: false,
    },
    navigation: {
      tabTitleToken: "wizard.hotel.tab.title.label",
    },
  },
  lx: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.travelocity.label.Activities",
      locSubtitleTextToken: "wizard.travelocity.subLabel.Activities",
      differentiatedTitles: false,
    },
  },
  car: {
    date: {
      maxDaysRange: 329,
    },
    timeFormats: {
      timeFormat12Hour: true,
    },
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.travelocity.label.Cars",
      locSubtitleTextToken: "wizard.travelocity.subLabel.Cars",
      differentiatedTitles: true,
    },
  },
  cruise: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.travelocity.label.Cruises",
      differentiatedTitles: false,
      visibleLobSubtitle: true,
      locSubtitleVariant: "1-855-257-1605",
    },
  },
  thirdPartyPackage: {
    heading: {
      locTitleToken: "wizard.thirdPartyPackage.heading.title",
    },
    date: {
      cutoffDate: "2023-01-01",
    },
  },
};
