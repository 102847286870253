import {
  globalConfig,
  packageConfig,
  lxConfig,
  vrConfig,
  carConfig,
  hotelConfig,
  hotelConfigHTG,
  flightConfig,
  thirdPartyPackageConfig,
  cruiseConfig,
  externalLinkTabConfig,
  MultiLOBWizardConfig,
  overrideConfigBySiteIdList,
} from "stores/wizard/config";

export interface StaticSiteIdConfig {
  default: MultiLOBWizardConfig;
  htg: MultiLOBWizardConfig;
  [siteid: number]: MultiLOBWizardConfig;
}

export class ConfigBuilder {
  private siteid?: number;

  private locale?: string;

  private type: string = "default";
  private siteIdConfig: StaticSiteIdConfig = {
    default: {
      global: { ...globalConfig },
      package: { ...packageConfig },
      lx: { ...lxConfig },
      vr: { ...vrConfig },
      car: { ...carConfig },
      hotel: { ...hotelConfig },
      thirdPartyPackage: { ...thirdPartyPackageConfig },
      cruise: { ...cruiseConfig },
      flight: { ...flightConfig },
      externalLinkTab: { ...externalLinkTabConfig },
    },
    htg: {
      global: { ...globalConfig },
      package: { ...packageConfig },
      lx: { ...lxConfig },
      vr: { ...vrConfig },
      car: { ...carConfig },
      thirdPartyPackage: { ...thirdPartyPackageConfig },
      cruise: { ...cruiseConfig },
      flight: { ...flightConfig },
      externalLinkTab: { ...externalLinkTabConfig },
      hotel: { ...hotelConfigHTG },
    },
  };

  /**
   * @constructs ConfigBuilder
   * @param siteid: If the site id exists, make a copy of the default configuration and add it
   *                to the siteIdConfig object
   * @param locale: If the locale exists, make a copy of the default configuration and add it
   *                to the siteIdConfig object
   */
  constructor(siteid?: number, locale?: string, type: string = "default") {
    this.siteid = siteid;
    this.locale = locale;
    this.type = type;
    if (this.siteid) {
      this.siteIdConfig[this.siteid] = JSON.parse(JSON.stringify(this.getSiteIdDefaultConfig()));
    }
  }

  /**
   * Traverses the override config
   * If property value is an object, continue down tree; otherwise check if the property exists and override
   * @param configObject: Config object to be overridden
   * @param overrideObject: Config object defining values to override. Must have the same key structure
   *                        as the config to be overridden
   */
  private performOverrides(overrideObject: any, configObject: any) {
    Object.keys(overrideObject).forEach((key) => {
      const keyValue = overrideObject[key];
      if (key === "__proto__" || key === "constructor") return;
      if (!Array.isArray(keyValue) && Object(keyValue) === keyValue) {
        this.performOverrides(keyValue, configObject[key]);
      } else {
        configObject[key] = keyValue;
      }
    });
  }

  /**
   * Checks for a LOCALE specific override. If it exists, traverse the override object and replace values in config.
   */
  private overrideByLocale() {
    if (this.locale && this.siteid) {
      const overrides = overrideConfigBySiteIdList[this.siteid][this.locale];
      if (overrides) {
        this.performOverrides(overrides, this.siteIdConfig[this.siteid]);
      }
    }
  }

  /**
   * Checks for a site-id specific override. If it exists, traverse the override object and replace values in config.
   */
  private overrideBySiteId() {
    if (this.siteid && overrideConfigBySiteIdList.hasOwnProperty(this.siteid)) {
      const overrides = overrideConfigBySiteIdList[this.siteid].default;
      if (overrides) {
        this.performOverrides(overrides, this.siteIdConfig[this.siteid]);
      }
      this.overrideByLocale();
    }
  }

  /**
   * Returns a specific siteIdConfig depending if it is for a HTG page type or not.
   */
  private getSiteIdDefaultConfig() {
    return this.siteIdConfig[this.type as keyof StaticSiteIdConfig];
  }

  /**
   * Public function for building the config object.
   * Calls individual override methods. The order in which these methods are called should reflect override precedence.
   * That is- higher-precedence overrides should be invoked after lower-precedence overrides.
   * Flex Manager overrides are done at the view layer, after the config is built, and always have the highest precedence.
   */
  public build(): MultiLOBWizardConfig {
    this.overrideBySiteId();

    if (this.siteid && this.siteIdConfig[this.siteid]) {
      return this.siteIdConfig[this.siteid];
    }
    return this.getSiteIdDefaultConfig();
  }
}
