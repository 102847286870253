import { SiteIdOverrideConfig } from "stores/wizard/config";
import { timeValues24HoursFormat } from "src/components/shared/TimeDropdown/config/time/timeValues";

export const siteId1070928: SiteIdOverrideConfig = {
  global: {
    heroImageSrc: "https://a.travel-assets.com/travel-assets-manager/cmct-2135/JP_Autumn_Osaka_1400x600.jpg",
  },
  package: {
    showAllSubLOB: false,
    lobLabels: {
      hotelsLabel: "wizard.properties.tab.title.label",
    },
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.taap.text",
    },
  },
  car: {
    location: {
      destination: {
        inputName: "returnName",
      },
      origin: {
        inputName: "pickupName",
      },
    },
    date: {
      queryParam: {
        start: "",
        end: "",
      },
    },
    form: {
      action: "https://cars.cartrawler.com/expedia/{locale}_JP/book#/vehicles",
    },
    isCarTrawler: true,
    showVendorOptions: false,
    clientID: 791862,
    residenceID: "JP",
    timeValues: timeValues24HoursFormat,
    defaultShowingTime: "10:30",
    includeGT: false,
    gtConfig: {
      location: {
        destination: {
          labelToken: "wizard.gt.propertyName.label",
        },
      },
    },
  },
  hotel: {
    useDialogTextWithoutCar: true,
    useTravelAgentSearchWizardConfig: true,
    packageRatesTermsUrl: "/static/default/default/taap/contracts/hpr/JP.HPR.012021.pdf",
    navigation: {
      tabTitleToken: "wizard.properties.tab.title.label",
    },
    heading: {
      hideLobTitle: true,
      locTitleToken: "wizard.taap.hotel.tab.title.label",
    },
  },
  lx: {
    navigation: {
      tabTitleToken: "wizard.activities.tab.title.label",
    },
    location: {
      destination: {
        labelToken: "wizard.activitiesIn.thingsToDoIn.label",
      },
    },
  },
};
