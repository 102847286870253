import { LxConfig } from "stores/wizard/config";

export const lxConfig: LxConfig = {
  navigation: {
    tabIcon: "lob_activities",
    tabTitleToken: "wizard.lx.tab.title.label",
  },
  location: {
    destination: {
      inputName: "location",
      icon: "place",
      invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
      invalidOriginSameAsDestinationKey: "",
      labelToken: "wizard.activities.thingsToDoIn.label",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.hotel",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.city",
      pwaDialogQueryParam: "destination-lx",
      essAdapterConfig: {
        lob: "ACTIVITIES",
        client: "Homepage",
        features: "consistent_display|lxcategory",
        maxResults: 10,
        regionType: 286,
        dest: true,
      },
      queryParams: {
        regionId: "rid",
      },
    },
  },
  date: {
    ISOSubmissionSupport: true,
    format: "yyyy-MM-dd",
    submissionFormat: "yyyy-MM-dd",
    daysBookableInAdvance: 328,
    midnightBookingOffset: 4,
    maxDaysRange: 14,
    invalidStartDateMessageToken: "wizard.datePicker.warning.activity.invalidFromDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.activity.invalidToDate.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "",
    invalidEndDateOutOfRangeMessageToken: "",
    start: {
      labelToken: "wizard.activity.startDate.label",
    },
    end: {
      labelToken: "wizard.activity.endDate.label",
    },
    queryParam: {
      start: "startDate",
      end: "endDate",
    },
    defaultOffsetDates: {
      start: 14,
      end: 15,
    },
    dialogId: "from-to",
  },
  form: {
    action: "/things-to-do/search",
    submitButton: {
      locToken: "wizard.submit.label",
      locWarningsToken: "wizard.submit.warnings",
      locWarningLinkToken: "wizard.submit.warning.link",
      locWarningsWithNumberToken: "wizard.submit.warningsWithNumber",
      locFirstWarningLinkToken: "wizard.submit.warnings.link",
    },
  },
  heading: {
    fixedTitle: false,
    locTitleToken: "wizard.mobile.label.lx",
    lobSubtitleBreakPoint: "on",
    visibleLobSubtitle: false,
    locSubtitleTextToken: "wizard.activities.subheadline.lookingForTickets.text",
    locSubtitleLinkToken: "wizard.activities.subheadline.searchEventTickets.text",
    locSubtitleLinkUrl: "",
  },
  elementId: "wizard-lx-pwa",
  halfWizard: false,
};
