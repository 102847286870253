import { CruiseConfig } from "stores/wizard/config";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "src/components/shared/TravelersField";

export const cruiseConfig: CruiseConfig = {
  navigation: {
    tabIcon: "lob_cruise",
    tabTitleToken: "wizard.cruise.tab.title.label",
  },
  location: {
    destination: {
      inputName: "destination",
      icon: "place",
      invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
      invalidOriginSameAsDestinationKey: "",
      labelToken: "wizard.package.flight.destination.label",
      pwaDialogQueryParam: "destination-cruise",
      essAdapterConfig: {
        lob: "CRUISES",
        client: "Homepage",
        features: "ta_hierarchy|postal_code",
        maxResults: 7,
        regionType: 2047,
        dest: true,
      },
      queryParams: {
        regionId: "rid",
      },
    },
  },
  date: {
    ISOSubmissionSupport: true,
    format: "yyyy-MM-dd",
    submissionFormat: "yyyy-MM-dd",
    daysBookableInAdvance: 1060,
    midnightBookingOffset: 4,
    maxDaysRange: 14,
    invalidStartDateMessageToken: "wizard.datePicker.warning.activity.invalidFromDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.activity.invalidToDate.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "",
    invalidEndDateOutOfRangeMessageToken: "",
    start: {
      labelToken: "wizard.cruise.date.start.label",
    },
    end: {
      labelToken: "wizard.cruise.date.end.label",
    },
    queryParam: {
      start: "earliest-departure-date",
      end: "latest-departure-date",
    },
    defaultOffsetDates: {
      start: 0,
      end: 30,
    },
    dialogId: "from-to",
  },
  travelers: {
    icon: "person",
    labelToken: "wizard.cruise.travelers.title",
    dialogTitleToken: "wizard.cruise.travelers.title",
    dialogCancelLabelToken: "wizard.roomPicker.closeButton.a11yLabel",
    childrenCaptionToken: "wizard.roomPicker.children.caption",
    infantsCaptionToken: "wizard.roomPicker.infants.caption",
    youngestChildAgeTextToken: "wizard.roomPicker.youngestChildAge.text",
    youngestInfantAgeTextToken: "wizard.roomPicker.youngestChildAge.text",
    invalidChildValueMessageToken: "wizard.travelers.warning.childAgeAllProvided.text",
    invalidChildrenValuesMessageToken: "wizard.travelers.warning.childrenAgesAllProvided.text",
    invalidInfantValueMessageToken: "wizard.travelers.warning.infantAgeAllProvided.text",
    invalidInfantsValuesMessageToken: "wizard.travelers.warning.infantAgesAllProvided.text",
    invalidLessThanNTravelersMessageToken: "wizard.travelers.warning.invalidNumberOfTravelers.text",
    invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.warning.unattendedInfantOnLap.text",
    travelersToken: "wizard.roomPicker.travelers",
    roomsGuestsToken: "wizard.roomPicker.roomsGuests",
    minBookableTravelers: 1,
    defaultTravelers: 2,
    maxBookableTravelers: 5,
    minChildAge: 0,
    maxChildAge: 17,
    minInfantAge: 0,
    maxInfantAge: 1,
    minAdultsPerRoom: 1,
    maxAdultsPerRoom: 5,
    minChildrenPerRoom: 0,
    maxChildrenPerRoom: 4,
    minInfantsPerRoom: 0,
    maxInfantsPerRoom: 0,
    maxRooms: 1,
    validateChildInfantValues: true,
    withAges: true,
    withRooms: false,
    withFlight: false,
    withInfants: false,
    hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.CRUISE_SEARCH,
  },
  form: {
    action: "/Cruise-Search",
    submitButton: {
      locToken: "wizard.submit.label",
      locWarningsToken: "wizard.submit.warnings",
      locWarningLinkToken: "wizard.submit.warning.link",
      locWarningsWithNumberToken: "wizard.submit.warningsWithNumber",
      locFirstWarningLinkToken: "wizard.submit.warnings.link",
    },
  },
  heading: {
    fixedTitle: false,
    lobSubtitleBreakPoint: "on",
    locSubtitleTextToken: "wizard.cruises.subheadline.expertAdviceContact",
    locSubtitleVariant: "",
    locTitleToken: "wizard.mobile.label.cruise",
    visibleLobSubtitle: false,
  },
  elementId: "wizard-cruise-pwa",
  displayWizard3pp: false,
  wizard3pp: {
    actionUrl: "",
    destinations: [],
    durations: [],
    departurePorts: [],
    cruiseLines: [],
    dateRanges: [],
    queryParam: {
      destination: "destination",
      departure: "departure",
      cruiseLine: "company",
      duration: "duration",
      dateRange: "date",
    },
    invalidLocKeys: {
      destination: "wizard.destination.warning.invalidDestination.text",
      departure: "wizard.origin.warning.invalidOrigin.text",
      cruiseLine: "wizard.package.invalid.header.selectAnother.text",
      duration: "wizard.thirdPartyPackage.invalidDuration.text",
      dateRange: "wizard.datePicker.warning.invalidDepartingDate.text",
    },
    labelLocKeys: {
      destination: "wizard.destination.label",
      departure: "wizard.cruise3pp.departurePort.label",
      cruiseLine: "wizard.cruise3pp.cruiseLine",
      duration: "wizard.cruise3pp.tripDuration.label",
      dateRange: "wizard.cruise3pp.sailingDate.label",
    },
  },
  cruiseLine: "",
};
