import { SiteIdOverrideConfig } from "stores/wizard/config";
import { destinationsAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/destinationsAu";
import { departurePortsAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/departurePortsAu";
import { cruisesLineAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/cruisesLineAu";
import { durationsAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/durationsAu";
import { dateRangesAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/dateRangesAu";

export const siteId25: SiteIdOverrideConfig = {
  car: {
    date: {
      maxDaysRange: 329,
    },
  },
  cruise: {
    date: {
      start: {
        labelToken: "wizard.cruise3pp.sailingDate.label",
      },
      queryParam: {
        start: "date",
      },
    },
    displayWizard3pp: true,
    wizard3pp: {
      destinations: destinationsAu,
      departurePorts: departurePortsAu,
      durations: durationsAu,
      cruiseLines: cruisesLineAu,
      actionUrl: "https://cruise.expedia.com.au/cruises/cruisedefault2.jsp",
      queryParam: {
        duration: "dur",
        destination: "destination",
        cruiseLine: "cruiseline",
        departure: "dport",
      },
      dateRanges: dateRangesAu,
    },
    heading: {
      lobSubtitleBreakPoint: "on",
      visibleLobSubtitle: true,
      locSubtitleVariant: "1800 030 501",
    },
  },
};
