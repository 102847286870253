import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId70463: SiteIdOverrideConfig = {
  hotel: {
    heading: {
      locTitleToken: "wizard.ebookers.hotels.tab.title",
    },
    navigation: {
      tabTitleToken: "wizard.ebookers.hotels.title",
    },
  },
  package: {
    heading: {
      locTitleToken: "wizard.ebookers.packages.tab.title",
    },
    navigation: {
      tabTitleToken: "wizard.ebookers.packages.title",
    },
    lobLabels: {
      hotelsLabel: "wizard.Comet.hotel.tab.title.label",
      hotelsEnhancedLabel: "wizard.comet.packages.hotelSelected.pill.text",
      hotelsEnhancedVariantLabel: "wizard.comet.packages.hotel.pill.text.variant",
    },
  },
};
