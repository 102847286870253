import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId500051: SiteIdOverrideConfig = {
  car: {
    disableVendorCouponCode: true,
    iHaveDiscount: "wizard.car.having.brand.title",
    location: {
      origin: {
        invalidLocationMessageKey: "wizard.origin.error.invalidOrigin.text",
      },
      destination: {
        invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
      },
    },
    date: {
      invalidStartDateMessageToken: "wizard.datePicker.error.invalidPickupDate.text",
      invalidEndDateMessageToken: "wizard.datePicker.error.invalidDropoffDate.text",
      invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
      invalidStartTimeMessageToken: "wizard.time.error.pickup.invalid",
    },
    travelers: {
      invalidChildValueMessageToken: "wizard.travelers.error.childAgeAllProvided.text",
      invalidChildrenValuesMessageToken: "wizard.travelers.error.childrenAgesAllProvided.text",
      invalidInfantValueMessageToken: "wizard.travelers.error.infantAgeAllProvided.text",
      invalidInfantsValuesMessageToken: "wizard.travelers.error.infantAgesAllProvided.text",
      invalidLessThanNTravelersMessageToken: "wizard.travelers.error.invalidNumberOfTravelers.text",
      invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.error.unattendedInfantOnLap.text",
    },
    invalidDriversAgeMessageToken: "wizard.car.error.invalidDriverAge",
    gtConfig: {
      location: {
        origin: {
          invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
        },
        destination: {
          invalidLocationMessageKey: "wizard.origin.error.invalidOrigin.text",
        },
      },
      date: {
        invalidStartDateMessageToken: "wizard.datePicker.error.invalidDepartingDate.text",
        invalidEndDateMessageToken: "wizard.datePicker.error.invalidDropoffDate.text",
        invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
        invalidStartTimeMessageToken: "wizard.time.error.invalid",
        invalidEndTimeMessageToken: "wizard.time.error.pickuptime.invalid",
      },
      additionalSingleDate: {
        invalidStartDateMessageToken: "wizard.datePicker.error.invalidDepartingDate.text",
        invalidEndDateMessageToken: "wizard.datePicker.error.invalidDropoffDate.text",
        invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
        invalidStartTimeMessageToken: "wizard.time.error.invalid",
        invalidEndTimeMessageToken: "wizard.time.error.pickuptime.invalid",
      },
    },
  },
  flight: {
    date: {
      invalidStartDateMessageToken: "wizard.datePicker.error.invalidDepartingDate.text",
      invalidEndDateMessageToken: "wizard.datePicker.error.invalidReturningDate.text",
      invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
    },
    location: {
      destination: {
        invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
        invalidOriginSameAsDestinationKey: "wizard.destination.error.originDifferentFromDestination.text",
      },
      origin: {
        invalidLocationMessageKey: "wizard.origin.error.invalidOrigin.text",
      },
    },
    hotelDate: {
      invalidStartDateMessageToken: "wizard.datePicker.error.invalidFromDate.text",
      invalidEndDateMessageToken: "wizard.datePicker.error.invalidToDate.text",
      invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
      invalidStartDateOutOfRangeMessageToken: "wizard.datePicker.error.checkInDateOutOfRange.text",
      invalidEndDateOutOfRangeMessageToken: "wizard.datePicker.error.checkOutDateOutOfRange.text",
      invalidOnewayHotelStartDateMessageToken: "wizard.datePicker.error.invalidOneWayCheckInDate.text",
    },
    travelers: {
      invalidChildValueMessageToken: "wizard.travelers.error.childAgeAllProvided.text",
      invalidChildrenValuesMessageToken: "wizard.travelers.error.childrenAgesAllProvided.text",
      invalidInfantValueMessageToken: "wizard.travelers.error.infantAgeAllProvided.text",
      invalidInfantsValuesMessageToken: "wizard.travelers.error.infantAgesAllProvided.text",
      invalidLessThanNTravelersMessageToken: "wizard.travelers.error.invalidNumberOfTravelers.text",
      invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.error.unattendedInfantOnLap.text",
      invalidNumberOfInfantPerTravelerMessageToken: "wizard.travelers.error.invalidNumberOfInfantPerTraveler.text",
    },
  },
  global: {
    date: {
      invalidDateRangeEndBeforeStart: "wizard.datePicker.error.endDateBeforeStartDate.text",
    },
  },
  hotel: {
    location: {
      origin: {
        invalidLocationMessageKey: "wizard.origin.error.invalidOrigin.text",
      },
      destination: {
        invalidOriginSameAsDestinationKey: "wizard.destination.error.originDifferentFromDestination.text",
        invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
      },
    },
    date: {
      invalidStartDateMessageToken: "wizard.datePicker.error.invalidFromDate.text",
      invalidEndDateMessageToken: "wizard.datePicker.error.invalidToDate.text",
      invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
    },
    travelers: {
      invalidChildValueMessageToken: "wizard.travelers.error.childAgeAllProvided.text",
      invalidChildrenValuesMessageToken: "wizard.travelers.error.childrenAgesAllProvided.text",
      invalidInfantValueMessageToken: "wizard.travelers.error.infantAgeAllProvided.text",
      invalidInfantsValuesMessageToken: "wizard.travelers.error.infantAgesAllProvided.text",
      invalidLessThanNTravelersMessageToken: "wizard.travelers.error.invalidNumberOfTravelers.text",
    },
  },
  package: {
    date: {
      invalidStartDateMessageToken: "wizard.datePicker.error.invalidDepartingDate.text",
      invalidEndDateMessageToken: "wizard.datePicker.error.invalidReturningDate.text",
      invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
    },
    location: {
      destination: {
        invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
        invalidOriginSameAsDestinationKey: "wizard.destination.error.originDifferentFromDestination.text",
      },
      origin: {
        invalidLocationMessageKey: "wizard.origin.error.invalidOrigin.text",
      },
    },
    hotelDestination: {
      invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
      invalidOriginSameAsDestinationKey: "wizard.destination.error.originDifferentFromDestination.text",
    },
    partialStayDate: {
      invalidStartDateMessageToken: "wizard.datePicker.error.invalidFromDate.text",
      invalidEndDateMessageToken: "wizard.datePicker.error.invalidToDate.text",
      invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
      invalidStartDateOutOfRangeMessageToken: "wizard.datePicker.error.checkInDateOutOfRange.text",
      invalidEndDateOutOfRangeMessageToken: "wizard.datePicker.error.checkOutDateOutOfRange.text",
    },
    travelers: {
      invalidChildValueMessageToken: "wizard.travelers.error.childAgeAllProvided.text",
      invalidChildrenValuesMessageToken: "wizard.travelers.error.childrenAgesAllProvided.text",
      invalidInfantValueMessageToken: "wizard.travelers.error.infantAgeAllProvided.text",
      invalidInfantsValuesMessageToken: "wizard.travelers.error.infantAgesAllProvided.text",
      invalidLessThanNTravelersMessageToken: "wizard.travelers.error.invalidNumberOfTravelers.text",
      invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.error.unattendedInfantOnLap.text",
      invalidNumberOfInfantPerTravelerMessageToken: "wizard.travelers.error.invalidNumberOfInfantPerTraveler.text",
    },
    subLOB: {
      flightCar: {
        travelers: {
          invalidChildValueMessageToken: "wizard.travelers.error.childAgeAllProvided.text",
          invalidChildrenValuesMessageToken: "wizard.travelers.error.childrenAgesAllProvided.text",
          invalidInfantValueMessageToken: "wizard.travelers.error.infantAgeAllProvided.text",
          invalidInfantsValuesMessageToken: "wizard.travelers.error.infantAgesAllProvided.text",
          invalidLessThanNTravelersMessageToken: "wizard.travelers.error.invalidNumberOfTravelers.text",
          invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.error.unattendedInfantOnLap.text",
          invalidNumberOfInfantPerTravelerMessageToken: "wizard.travelers.error.invalidNumberOfInfantPerTraveler.text",
        },
      },
      hotelCar: {
        date: {
          invalidStartDateMessageToken: "wizard.datePicker.error.invalidFromDate.text",
          invalidEndDateMessageToken: "wizard.datePicker.error.invalidToDate.text",
          invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
        },
        travelers: {
          invalidChildValueMessageToken: "wizard.travelers.error.childAgeAllProvided.text",
          invalidChildrenValuesMessageToken: "wizard.travelers.error.childrenAgesAllProvided.text",
          invalidInfantValueMessageToken: "wizard.travelers.error.infantAgeAllProvided.text",
          invalidInfantsValuesMessageToken: "wizard.travelers.error.infantAgesAllProvided.text",
          invalidLessThanNTravelersMessageToken: "wizard.travelers.error.invalidNumberOfTravelers.text",
          invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.error.unattendedInfantOnLap.text",
          invalidNumberOfInfantPerTravelerMessageToken: "wizard.travelers.error.invalidNumberOfInfantPerTraveler.text",
        },
      },
    },
    onewayFlight: {
      partialStayDate: {
        invalidStartDateOutOfRangeMessageToken: "wizard.package.hotel.error.invalidCheckInDate.oneway",
        invalidEndDateOutOfRangeMessageToken: "wizard.package.hotel.error.invalidCheckOutDate.oneway",
        invalidStartDateMessageToken: "wizard.package.hotel.error.invalidCheckInDate.oneway",
        invalidEndDateMessageToken: "wizard.package.hotel.error.invalidCheckOutDate.oneway",
      },
      location: {
        destination: {
          invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
          invalidOriginSameAsDestinationKey: "wizard.destination.error.originDifferentFromDestination.text",
        },
        origin: {
          invalidLocationMessageKey: "wizard.origin.error.invalidOrigin.text",
        },
      },
    },
    multiCityFlight: {
      partialStayDate: {
        invalidStartDateOutOfRangeMessageToken: "wizard.package.hotel.error.invalidCheckInDate.multicity",
        invalidEndDateOutOfRangeMessageToken: "wizard.package.hotel.error.invalidCheckOutDate.multicity",
        invalidStartDateMessageToken: "wizard.package.hotel.error.invalidCheckOutDate.multicity",
        invalidEndDateMessageToken: "wizard.package.hotel.error.invalidCheckOutDate.multicity",
      },
      location: {
        destination: {
          invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
          invalidOriginSameAsDestinationKey: "wizard.destination.error.originDifferentFromDestination.text",
        },
        origin: {
          invalidLocationMessageKey: "wizard.origin.error.invalidOrigin.text",
        },
      },
    },
  },
  thirdPartyPackage: {
    origin: {
      invalidMessage: "wizard.origin.error.invalidOrigin.text",
    },
    destination: {
      invalidMessage: "wizard.destination.error.invalidDestination.text",
    },
    duration: {
      invalidMessage: "wizard.thirdPartyPackage.error.invalidDuration.text",
    },
    date: {
      invalidStartDateMessageToken: "wizard.datePicker.error.invalidFromDate.text",
      invalidEndDateMessageToken: "wizard.datePicker.error.invalidToDate.text",
      invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
    },
    travelers: {
      invalidChildValueMessageToken: "wizard.travelers.error.childAgeAllProvided.text",
      invalidChildrenValuesMessageToken: "wizard.travelers.error.childrenAgesAllProvided.text",
      invalidInfantValueMessageToken: "wizard.travelers.error.infantAgeAllProvided.text",
      invalidInfantsValuesMessageToken: "wizard.travelers.error.infantAgesAllProvided.text",
      invalidLessThanNTravelersMessageToken: "wizard.travelers.error.invalidNumberOfTravelers.text",
    },
  },
  lx: {
    location: {
      destination: {
        invalidLocationMessageKey: "wizard.destination.error.invalidDestination.text",
      },
    },
    date: {
      invalidStartDateMessageToken: "wizard.datePicker.error.activity.invalidFromDate.text",
      invalidEndDateMessageToken: "wizard.datePicker.error.activity.invalidToDate.text",
      invalidMaxDatesRangeMessageToken: "wizard.datePicker.error.maxDatesRange.text",
    },
  },
};
