import { VrConfig } from "stores/wizard/config";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "src/components/shared/TravelersField";
import { VR_PROPERTY_TYPES_ALL } from "stores/wizard/config/typings";

export const vrConfig: VrConfig = {
  navigation: {
    tabIcon: "lob_vacation_rentals",
    tabTitleToken: "wizard.vr.tab.title.label",
  },
  location: {
    destination: {
      inputName: "destination",
      icon: "place",
      invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
      invalidOriginSameAsDestinationKey: "",
      labelToken: "wizard.hotel.destination.label",
      typeaheadLabelToken: "wizard.vr.destination.placeholder",
      pwaDialogQueryParam: "destination-vr",
      essAdapterConfig: {
        lob: "HOTELS",
        client: "Homepage",
        features: "postal_code|consistent_display|google|multiregion_parent|ta_hierarchy",
        maxResults: 10,
        regionType: 2047,
        dest: true,
      },
      queryParams: {
        regionId: "regionId",
        latLong: "latLong",
      },
    },
  },
  date: {
    ISOSubmissionSupport: true,
    format: "yyyy-MM-dd",
    submissionFormat: "yyyy-MM-dd",
    daysBookableInAdvance: 500,
    midnightBookingOffset: 4,
    maxDaysRange: 28,
    invalidStartDateMessageToken: "wizard.datePicker.warning.invalidFromDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.invalidToDate.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "",
    invalidEndDateOutOfRangeMessageToken: "",
    start: {
      labelToken: "datePicker.fromDate.label",
    },
    end: {
      labelToken: "datePicker.toDate.label",
    },
    queryParam: {
      start: "startDate",
      end: "endDate",
    },
    defaultOffsetDates: {
      start: 14,
      end: 15,
    },
    dialogId: "check-in-out",
  },
  travelers: {
    icon: "person",
    labelToken: "wizard.roomPicker.title",
    dialogTitleToken: "wizard.roomPicker.title",
    dialogCancelLabelToken: "wizard.roomPicker.closeButton.a11yLabel",
    childrenCaptionToken: "wizard.roomPicker.children.caption",
    infantsCaptionToken: "",
    youngestChildAgeTextToken: "wizard.roomPicker.youngestChildAge.text",
    youngestInfantAgeTextToken: "",
    invalidChildValueMessageToken: "wizard.travelers.warning.childAgeAllProvided.text",
    invalidChildrenValuesMessageToken: "wizard.travelers.warning.childrenAgesAllProvided.text",
    invalidInfantValueMessageToken: "wizard.travelers.warning.infantAgeAllProvided.text",
    invalidInfantsValuesMessageToken: "wizard.travelers.warning.infantAgesAllProvided.text",
    invalidLessThanNTravelersMessageToken: "wizard.travelers.warning.invalidNumberOfTravelers.text",
    invalidUnattendedInfantOnLapMessageToken: "",
    travelersToken: "wizard.roomPicker.travelers",
    roomsGuestsToken: "wizard.roomPicker.roomsGuests",
    minBookableTravelers: 1,
    maxBookableTravelers: 14,
    minChildAge: 0,
    maxChildAge: 17,
    minInfantAge: 0,
    maxInfantAge: 1,
    minAdultsPerRoom: 1,
    maxAdultsPerRoom: 14,
    minChildrenPerRoom: 0,
    maxChildrenPerRoom: 6,
    minInfantsPerRoom: 0,
    maxInfantsPerRoom: 0,
    maxRooms: 8,
    validateChildInfantValues: true,
    withRooms: true,
    withAges: true,
    withFlight: false,
    withInfants: false,
    hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.HOTEL_SEARCH,
  },
  form: {
    action: "/Hotel-Search",
    submitButton: {
      locToken: "wizard.submit.label",
    },
  },
  heading: {
    fixedTitle: false,
    lobSubtitleBreakPoint: "off",
    locTitleToken: "wizard.mobile.label.vr",
  },
  elementId: "wizard-vr-pwa",
  searchButtonAlongsideDates: true,
  payWithPointsCheckedToken: "wizard.hotel.pwpToggle.points",
  payWithCashCheckedToken: "wizard.hotel.pwpToggle.cash",
  vrPropertyTypes: VR_PROPERTY_TYPES_ALL,
};
