import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId500053: SiteIdOverrideConfig = {
  flight: {
    cabinClass: {
      econonomy: "econonomy",
      firstClass: "firstClass",
    },
  },
  hotel: {
    payWithPointsCheckedToken: "wizard.alaska.hotel.pwpToggle.points",
    payWithCashCheckedToken: "wizard.alaska.hotel.pwpToggle.cash",
  },
};
