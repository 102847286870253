import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId1: SiteIdOverrideConfig = {
  global: {
    includeSingleLOBsWithinPackages: true,
  },
  package: {
    lobLabels: {
      hotelsEnhancedLabel: "wizard.packages.staySelected.pill.text",
      hotelsEnhancedVariantLabel: "wizard.packages.stay.pill.text.variant",
    },
  },
  car: {
    date: {
      maxDaysRange: 329,
    },
    timeFormats: {
      timeFormat12Hour: true,
    },
    AARPCheckbox: true,
  },
  cruise: {
    heading: {
      lobSubtitleBreakPoint: "on",
      visibleLobSubtitle: true,
      locSubtitleVariant: "1-800-916-8586",
    },
  },
  lx: {
    heading: {
      visibleLobSubtitle: true,
      locSubtitleLinkUrl: "https://www.expedia.com/event-tickets/",
      locSubtitleTextToken: "wizard.activities.subheadline.lookingForTickets.text.v2",
    },
  },
};

export const cardLink = {
  feeCardLink: "https://www.expedia.com/user/login?loyaltyRewardsEnrollSignin=true&citiCardType=elite",
  noFeeCardLink: "https://www.expedia.com/user/login?loyaltyRewardsEnrollSignin=true&citiCardType=noFee",
};
