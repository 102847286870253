import { SiteIdOverrideConfig } from "stores/wizard/config";
import { defaultLocationValue_en } from "../../staticConfigs/thirdPartyPackage/thirdPartyPackageConfig";
import { defaultDestinationsSiteId70472, defaultOriginsSiteId70472 } from "./siteId70472";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const siteId70472_en_GB: SiteIdOverrideConfig = {
  thirdPartyPackage: {
    origins: [defaultLocationValue_en, ...defaultOriginsSiteId70472],
    destinations: [defaultLocationValue_en, ...defaultDestinationsSiteId70472],
  },
};
