import { locKeys } from "src/components/flexComponents/WizardCarPWA/components/l10n";
import { timeValues } from "src/components/shared/TimeDropdown/config";
import { GtConfig } from "../typings";

const commonDateProps = {
  ISOSubmissionSupport: true,
  format: "yyyy-MM-dd",
  submissionFormat: "yyyy-MM-dd",
  daysBookableInAdvance: 328,
  maxDaysRange: 331,
  invalidStartDateMessageToken: "wizard.datePicker.warning.invalidDepartingDate.text",
  invalidEndDateMessageToken: "wizard.datePicker.warning.invalidDropoffDate.text",
  invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
  invalidStartDateOutOfRangeMessageToken: "",
  invalidEndDateOutOfRangeMessageToken: "",
  invalidStartTimeMessageToken: "wizard.time.invalid",
  invalidEndTimeMessageToken: "wizard.time.pickuptime.invalid",
  midnightBookingOffset: 4,
};

const gtPropertyLocation = {
  inputName: "hotelName",
  icon: "lob_hotels",
  labelToken: locKeys.gtProperty,
  typeaheadLabelToken: locKeys.gtProperty,
  pwaDialogQueryParam: "destination-gt",
  invalidLocationMessageKey: "wizard.origin.warning.invalidOrigin.text",
  invalidOriginSameAsDestinationKey: "",
  essAdapterConfig: {
    lob: "HOTELS",
    client: "Homepage",
    features: "proximity",
    maxResults: 7,
    regionType: 128,
    regionId: undefined,
    dest: true,
  },
};

const gtAirportLocation = {
  inputName: "location",
  icon: "place",
  labelToken: locKeys.gtAirport,
  typeaheadLabelToken: locKeys.gtAirport,
  pwaDialogQueryParam: "origin-car",
  invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
  invalidOriginSameAsDestinationKey: "",
  essAdapterConfig: {
    lob: "FLIGHTS",
    client: "Homepage",
    features: "",
    maxResults: 7,
    regionType: 1,
    dest: true,
  },
};

export const gtConfig: GtConfig = {
  navigation: {
    tabIcon: "",
    tabTitleToken: "wizard.gt.tab.title.label",
  },
  heading: {
    fixedTitle: false,
    locTitleToken: "",
  },
  elementId: "wizard-gt-pwa",
  location: {
    origin: gtAirportLocation,
    destination: gtPropertyLocation,
  },
  date: {
    ...commonDateProps,
    start: {
      labelToken: locKeys.gtArrivalDate,
    },
    end: {
      labelToken: locKeys.gtDepartureDate,
    },
    queryParam: {
      start: "pickUpDate",
      end: "dropOffDate",
    },
    dialogId: "departing-returning",
  },
  timeValues,
  switchLocKey: "wizard.switchero.icon.description",
  switchActionLocKey: "wizard.switchero.action.text",
  additionalSingleDate: {
    ...commonDateProps,
    start: {
      labelToken: locKeys.gtDepartureDate,
    },
    end: {
      labelToken: locKeys.gtDepartureDate,
    },
    queryParam: {
      start: "dropOffDate",
      end: "dropOffDate",
    },
    dialogId: `departing-returning-additional`,
  },
  form: {
    action: "/ground-transfers/search",
    submitButton: {
      locToken: "",
    },
  },
};
