import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId70151: SiteIdOverrideConfig = {
  flight: {
    heading: {
      locTitleToken: "wizard.lastminute.flights.title",
    },
  },
  package: {
    heading: {
      locTitleToken: "wizard.lastminute.packages.title",
    },
    lobLabels: {
      hotelsLabel: "wizard.wotif.hotels.tab.label",
    },
  },
  hotel: {
    heading: {
      locTitleToken: "wizard.lastminute.hotels.title",
    },
    navigation: {
      tabTitleToken: "wizard.wotif.hotels.tab.label",
    },
  },
  lx: {
    heading: {
      locTitleToken: "wizard.lastminute.activities.title",
    },
  },
  car: {
    date: {
      maxDaysRange: 329,
    },
    heading: {
      locTitleToken: "wizard.lastminute.cars.title",
    },
    navigation: {
      tabTitleToken: "wizard.wotif.label.Cars",
    },
  },
};
