import { SiteIdOverrideConfig } from "stores/wizard/config";

const defaultOffsetDates = {
  start: 1,
  end: 2,
};

export const siteId4: SiteIdOverrideConfig = {
  package: {
    heading: {
      visibleLobSubtitle: false,
    },
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.text",
    },
    date: {
      defaultOffsetDates,
    },
  },
  cruise: {
    heading: {
      lobSubtitleBreakPoint: "on",
      visibleLobSubtitle: true,
      locSubtitleVariant: "1-888-444-1695",
    },
  },
  thirdPartyPackage: {
    showGroupLink: true,
    date: {
      cutoffDate: "2023-01-01",
    },
  },
};
