import { PackageConfig } from "stores/wizard/config";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "src/components/shared/TravelersField";
import { FlightLegConfig } from "../flight/typings";

const travelersConfig = {
  icon: "person",
  labelToken: "wizard.roomPicker.travelers.title",
  dialogTitleToken: "wizard.roomPicker.travelers.title",
  dialogCancelLabelToken: "wizard.roomPicker.closeButton.a11yLabel",
  childrenCaptionToken: "wizard.roomPicker.children.withInfants.caption",
  infantsCaptionToken: "wizard.roomPicker.infants.caption",
  youngestChildAgeTextToken: "",
  youngestInfantAgeTextToken: "wizard.roomPicker.youngestChildAge.text",
  invalidChildValueMessageToken: "wizard.travelers.warning.childAgeAllProvided.text",
  invalidChildrenValuesMessageToken: "wizard.travelers.warning.childrenAgesAllProvided.text",
  invalidInfantValueMessageToken: "wizard.travelers.warning.infantAgeAllProvided.text",
  invalidInfantsValuesMessageToken: "wizard.travelers.warning.infantAgesAllProvided.text",
  invalidLessThanNTravelersMessageToken: "wizard.travelers.warning.invalidNumberOfTravelers.text",
  invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.warning.unattendedInfantOnLap.text",
  invalidNumberOfInfantPerTravelerMessageToken: "wizard.travelers.warning.invalidNumberOfInfantPerTraveler.text",
  travelersToken: "wizard.roomPicker.travelers",
  roomsGuestsToken: "wizard.roomPicker.roomsGuests",
  minBookableTravelers: 1,
  defaultTravelers: 2,
  maxBookableTravelers: 6,
  minAdultAge: 12,
  minChildAge: 2,
  maxChildAge: 17,
  minInfantAge: 0,
  maxInfantAge: 1,
  minInfantPerTraveler: 2,
  minAdultsPerRoom: 1,
  maxAdultsPerRoom: 6,
  minChildrenPerRoom: 0,
  maxChildrenPerRoom: 6,
  minInfantsPerRoom: 0,
  maxInfantsPerRoom: 6,
  maxRooms: 6,
  validateChildInfantValues: true,
  withRooms: true,
  withAges: true,
  withFlight: true,
  withInfants: true,
  hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.PACKAGE_SEARCH,
  noRehydratationValues: {
    numOfTravelers: 2,
    name: "adultsPerRoom[1]",
  },
};

const partialStayDateConfig = {
  ISOSubmissionSupport: true,
  format: "yyyy-MM-dd",
  submissionFormat: "yyyy-MM-dd",
  daysBookableInAdvance: 331,
  midnightBookingOffset: 4,
  maxDaysRange: 28,
  invalidStartDateMessageToken: "wizard.datePicker.warning.invalidFromDate.text",
  invalidEndDateMessageToken: "wizard.datePicker.warning.invalidToDate.text",
  invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
  invalidStartDateOutOfRangeMessageToken: "wizard.datePicker.warning.checkInDateOutOfRange.text",
  invalidEndDateOutOfRangeMessageToken: "wizard.datePicker.warning.checkOutDateOutOfRange.text",
  start: {
    labelToken: "datePicker.fromDate.label",
  },
  end: {
    labelToken: "datePicker.toDate.label",
  },
  queryParam: {
    start: "checkInDate",
    end: "checkOutDate",
  },
  defaultOffsetDates: {
    start: 14,
    end: 15,
  },
  dialogId: "partial-stay-check-in-out",
};

const MAX_NUMBER_OF_ADDITIONAL_LEGS = 4;

const createLegConfigs = () => {
  const legArray = new Array<FlightLegConfig>(MAX_NUMBER_OF_ADDITIONAL_LEGS);

  //start additional leg count at 2; legs are 1-indexed with the default origin/dest input as leg 1
  for (let i = 0; i < MAX_NUMBER_OF_ADDITIONAL_LEGS; i++) {
    legArray[i] = flightLegConfig(i + 1);
  }

  return legArray;
};

const getMaxNumberOfAdditionalLegs = () => {
  return MAX_NUMBER_OF_ADDITIONAL_LEGS;
};

export const originDestinationCityAirport = () => {
  return {
    origin: {
      inputName: "origin",
      icon: "place",
      invalidLocationMessageKey: "wizard.origin.warning.invalidOrigin.text",
      labelToken: "wizard.package.origin.label",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.origin",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
      pwaDialogQueryParam: "origin-package",
      essAdapterConfig: {
        lob: "PACKAGES",
        client: "Homepage",
        features: "ta_hierarchy|nearby_airport|consistent_display",
        maxResults: 9,
        regionType: 4959,
        dest: false,
      },
    },
    destination: {
      inputName: "destination",
      icon: "place",
      invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
      labelToken: "wizard.package.destination.label",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.destination",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
      pwaDialogQueryParam: "destination-package",
      essAdapterConfig: {
        lob: "PACKAGES",
        client: "Homepage",
        features: "ta_hierarchy|nearby_airport|consistent_display",
        maxResults: 9,
        regionType: 991,
        dest: true,
      },
    },
  };
};

export const flightLegConfig = (legNumber: number) => {
  return {
    date: {
      ISOSubmissionSupport: false,
      format: "yyyy-MM-dd",
      submissionFormat: "yyyy-MM-dd",
      daysBookableInAdvance: 331,
      midnightBookingOffset: 4,
      maxDaysRange: 0,
      invalidStartDateMessageToken: "wizard.datePicker.warning.invalidDepartingDate.text",
      invalidEndDateMessageToken: "",
      invalidMaxDatesRangeMessageToken: "",
      invalidStartDateOutOfRangeMessageToken: "",
      invalidEndDateOutOfRangeMessageToken: "",
      start: {
        labelToken: "wizard.departing.label",
      },
      end: {
        labelToken: "wizard.returning.label",
      },
      queryParam: {
        start: "",
        end: "",
      },
      defaultOffsetDates: {
        start: 14,
        end: 15,
      },
      dialogId: `flights-departure-${legNumber}`,
    },
    location: {
      destination: {
        inputName: `leg${legNumber}-destination`,
        icon: "place",
        invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
        invalidOriginSameAsDestinationKey: "wizard.destination.warning.originDifferentFromDestination.text",
        labelToken: "wizard.package.flight.destination.label",
        typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.destination",
        typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
        pwaDialogQueryParam: `destination-flight-${legNumber}`,
        essAdapterConfig: {
          lob: "FLIGHTS",
          client: "Homepage",
          features: "nearby_airport|ta_hierarchy|consistent_display",
          maxResults: 9,
          regionType: 4959,
          dest: true,
        },
      },
      origin: {
        inputName: `leg${legNumber}-origin`,
        icon: "place",
        invalidLocationMessageKey: "wizard.origin.warning.invalidOrigin.text",
        labelToken: "wizard.package.flight.origin.label",
        typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.origin",
        typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
        pwaDialogQueryParam: `origin-flight-${legNumber}`,
        essAdapterConfig: {
          lob: "FLIGHTS",
          client: "Homepage",
          features: "nearby_airport|ta_hierarchy|consistent_display",
          maxResults: 9,
          regionType: 4959,
          dest: false,
        },
      },
    },
  };
};

export const packageConfig: PackageConfig = {
  defaultLengthInDays: 3,
  lobLabels: {
    flightLabel: "wizard.flight.tab.title.label",
    hotelsLabel: "wizard.hotel.tab.title.label",
    carsLabel: "wizard.car.tab.title.label",
    flightEnhancedLabel: "wizard.packages.flightSelected.pill.text",
    hotelsEnhancedLabel: "wizard.packages.staySelected.pill.text",
    carsEnhancedLabel: "wizard.packages.carSelected.pill.text",
    flightEnhancedVariantLabel: "wizard.packages.flight.pill.text.variant",
    hotelsEnhancedVariantLabel: "wizard.packages.stay.pill.text.variant",
    carsEnhancedVariantLabel: "wizard.packages.car.pill.text.variant",
  },
  navigation: {
    tabIcon: "lob_packages",
    tabTitleToken: "wizard.package.lobTitle.packages.text",
    subTabTitleToken: "wizard.package.sublob.label",
  },
  location: {
    origin: {
      inputName: "origin",
      icon: "place",
      invalidLocationMessageKey: "wizard.origin.warning.invalidOrigin.text",
      labelToken: "wizard.package.origin.label",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.origin",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
      pwaDialogQueryParam: "origin-package",
      essAdapterConfig: {
        lob: "PACKAGES",
        client: "Homepage",
        features: "ta_hierarchy|nearby_airport|consistent_display",
        maxResults: 9,
        regionType: 4959,
        dest: false,
      },
    },
    destination: {
      inputName: "destination",
      icon: "place",
      invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
      invalidOriginSameAsDestinationKey: "wizard.destination.warning.originDifferentFromDestination.text",
      labelToken: "wizard.package.destination.label",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.destination",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
      pwaDialogQueryParam: "destination-package",
      essAdapterConfig: {
        lob: "PACKAGES",
        client: "Homepage",
        features: "ta_hierarchy|nearby_airport|consistent_display",
        maxResults: 9,
        regionType: 991,
        dest: true,
      },
    },
  },
  hotelDestination: {
    inputName: "hotelDestination",
    icon: "place",
    invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
    invalidOriginSameAsDestinationKey: "wizard.destination.warning.originDifferentFromDestination.text",
    labelToken: "wizard.hotel.destination.label",
    typeaheadLabelToken: "wizard.package.typeahead.placeholder",
    typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
    pwaDialogQueryParam: "hotel-destination",
    essAdapterConfig: {
      lob: "PACKAGES",
      client: "Homepage",
      features: "nearby_airport|ta_hierarchy|consistent_display",
      maxResults: 9,
      regionType: 991,
      dest: true,
    },
  },
  date: {
    ISOSubmissionSupport: true,
    format: "yyyy-MM-dd",
    submissionFormat: "yyyy-MM-dd",
    daysBookableInAdvance: 331,
    maxDaysRange: 28,
    invalidStartDateMessageToken: "wizard.datePicker.warning.invalidDepartingDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.invalidReturningDate.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "",
    invalidEndDateOutOfRangeMessageToken: "",
    midnightBookingOffset: 4,
    start: {
      labelToken: "wizard.departing.label",
    },
    end: {
      labelToken: "wizard.returning.label",
    },
    queryParam: {
      start: "fromDate",
      end: "toDate",
    },
    defaultOffsetDates: {
      start: 14,
      end: 15,
    },
    dialogId: "departing-returning",
  },
  partialStayDate: partialStayDateConfig,
  travelers: travelersConfig,
  subLOB: {
    flightCar: {
      travelers: {
        ...travelersConfig,
        withRooms: false,
        withFlight: true,
        withInfants: true,
      },
    },
    hotelCar: {
      date: {
        ISOSubmissionSupport: true,
        format: "yyyy-MM-dd",
        submissionFormat: "yyyy-MM-dd",
        daysBookableInAdvance: 500,
        midnightBookingOffset: 4,
        maxDaysRange: 28,
        invalidStartDateMessageToken: "wizard.datePicker.warning.invalidFromDate.text",
        invalidEndDateMessageToken: "wizard.datePicker.warning.invalidToDate.text",
        invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
        invalidStartDateOutOfRangeMessageToken: "",
        invalidEndDateOutOfRangeMessageToken: "",
        start: {
          labelToken: "datePicker.fromDate.label",
        },
        end: {
          labelToken: "datePicker.toDate.label",
        },
        queryParam: {
          start: "fromDate",
          end: "toDate",
        },
        dialogId: "departing-returning",
      },
      travelers: {
        ...travelersConfig,
        withRooms: true,
        withFlight: false,
        withInfants: true,
      },
    },
  },
  onewayFlight: {
    partialStayDate: {
      ...partialStayDateConfig,
      invalidStartDateOutOfRangeMessageToken: "wizard.package.hotel.invalid.checkInDate.oneway",
      invalidEndDateOutOfRangeMessageToken: "wizard.package.hotel.invalid.checkOutDate.oneway",
      invalidStartDateMessageToken: "wizard.package.hotel.invalid.checkInDate.oneway",
      invalidEndDateMessageToken: "wizard.package.hotel.invalid.checkOutDate.oneway",
    },
    location: originDestinationCityAirport(),
  },
  multiCityFlight: {
    partialStayDate: {
      ...partialStayDateConfig,
      invalidStartDateOutOfRangeMessageToken: "wizard.package.hotel.invalid.checkInDate.multicity",
      invalidEndDateOutOfRangeMessageToken: "wizard.package.hotel.invalid.checkOutDate.multicity",
      invalidStartDateMessageToken: "wizard.package.hotel.invalid.checkOutDate.multicity",
      invalidEndDateMessageToken: "wizard.package.hotel.invalid.checkOutDate.multicity",
    },
    location: originDestinationCityAirport(),
    maxNumberOfAdditionalLegs: getMaxNumberOfAdditionalLegs(),
    legs: createLegConfigs(),
    minNumberOfLegs: 2,
  },
  form: {
    action: "/flexibleshopping",
    submitButton: {
      locToken: "wizard.submit.label",
      locWarningsToken: "wizard.submit.warnings",
      locWarningLinkToken: "wizard.submit.warning.link",
      locWarningsWithNumberToken: "wizard.submit.warningsWithNumber",
      locFirstWarningLinkToken: "wizard.submit.warnings.link",
      subTextTokens: {
        fh: "wizard.search.btn.sub.copy.fh",
        fhc: "wizard.search.btn.sub.copy.fhc",
        fc: "wizard.search.btn.sub.copy.fc",
        hc: "wizard.search.btn.sub.copy.hc",
      },
    },
  },
  heading: {
    fixedTitle: false,
    lobSubtitleBreakPoint: "off",
    locSubtitleTextToken: "wizard.mobile.subLabel.package",
    locTitleToken: "wizard.mobile.label.package",
    visibleLobSubtitle: true,
  },
  showEnhancedLabels: false,
  showAllSubLOB: true,
  elementId: "wizard-package-pwa",
  minimumLOBsNeeded: 2,
  defaultLOBpackage: "",
  pillsLabelKey: "wizard.packages.valuePropSubtitle",
  multiHotelIDs: undefined,
  disableToggleOnPackage: false,
};
