import { ThirdPartyPackageConfig } from "stores/wizard/config";

import { TRAVELERS_HIDDEN_INPUT_TYPE } from "src/components/shared/TravelersField";

export const defaultLocationValue_de = {
  code: "0",
  name: "Beliebig",
};

export const defaultLocationValue_en = {
  code: "0",
  name: "Any",
};

export const thirdPartyPackageConfig: ThirdPartyPackageConfig = {
  navigation: {
    tabIcon: "lob_all_inclusive_vacations",
    tabTitleToken: "wizard.thirdPartyPackage.tab.title.label",
  },
  location: {},
  destinations: [],
  origins: [],
  durations: [],
  offersWithTransfer: {
    labelText: "wizard.thirdPartyPackage.offers.checkbox",
    isVisible: false,
  },
  origin: {
    invalidMessage: "wizard.origin.warning.invalidOrigin.text",
    placeholderText: "wizard.thirdPartyPackage.origin.label",
  },
  destination: {
    invalidMessage: "wizard.destination.warning.invalidDestination.text",
    placeholderText: "wizard.destination.label",
  },
  duration: {
    queryParam: "duration",
    invalidMessage: "wizard.thirdPartyPackage.invalidDuration.text",
    placeholderText: "wizard.package.vacationLength.text",
    // the api is returning just numeric values so we need to suffix this field - just CA is using this api
    daysTextApi: "days",
  },
  date: {
    ISOSubmissionSupport: true,
    format: "yyyy-MM-dd",
    submissionFormat: "yyyy-MM-dd",
    daysBookableInAdvance: 500,
    midnightBookingOffset: 4,
    maxDaysRange: 331,
    invalidStartDateMessageToken: "wizard.datePicker.warning.invalidFromDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.invalidToDate.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "",
    invalidEndDateOutOfRangeMessageToken: "",
    start: {
      labelToken: "wizard.departing.label",
    },
    end: {
      labelToken: "wizard.returning.label",
    },
    queryParam: {
      start: "departure",
      end: "",
    },
    defaultOffsetDates: {
      start: 1,
      end: 2,
    },
    dialogId: `all-inclusive-departure`,
  },
  travelers: {
    icon: "person",
    labelToken: "wizard.roomPicker.travelers.title",
    dialogTitleToken: "wizard.roomPicker.travelers.title",
    dialogCancelLabelToken: "wizard.roomPicker.closeButton.a11yLabel",
    childrenCaptionToken: "wizard.roomPicker.children.caption",
    infantsCaptionToken: "",
    youngestChildAgeTextToken: "wizard.roomPicker.youngestChildAge.text",
    youngestInfantAgeTextToken: "",
    invalidChildValueMessageToken: "wizard.travelers.warning.childAgeAllProvided.text",
    invalidChildrenValuesMessageToken: "wizard.travelers.warning.childrenAgesAllProvided.text",
    invalidInfantValueMessageToken: "wizard.travelers.warning.infantAgeAllProvided.text",
    invalidInfantsValuesMessageToken: "wizard.travelers.warning.infantAgesAllProvided.text",
    invalidLessThanNTravelersMessageToken: "wizard.travelers.warning.invalidNumberOfTravelers.text",
    invalidUnattendedInfantOnLapMessageToken: "",
    travelersToken: "wizard.roomPicker.travelers",
    roomsGuestsToken: "wizard.roomPicker.roomsGuests",
    minBookableTravelers: 1,
    maxBookableTravelers: 7,
    minChildAge: 0,
    maxChildAge: 17,
    minInfantAge: 0,
    maxInfantAge: 1,
    minAdultsPerRoom: 1,
    maxAdultsPerRoom: 14,
    minChildrenPerRoom: 0,
    maxChildrenPerRoom: 6,
    minInfantsPerRoom: 0,
    maxInfantsPerRoom: 0,
    maxRooms: 0,
    validateChildInfantValues: true,
    withRooms: false,
    withAges: true,
    withFlight: false,
    withInfants: false,
    hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.THIRD_SEARCH,
  },
  form: {
    action: "/all-inclusive-vacation",
    submitButton: {
      locToken: "wizard.submit.label",
    },
  },
  heading: {
    fixedTitle: false,
    lobSubtitleBreakPoint: "on",
    locTitleToken: "wizard.mobile.label.hotel",
  },
  elementId: "wizard-thirdPartyPackage-pwa",
  defaultPackageForm: "packages",
  groupLinkKey: "wizard.thirdPartyPackage.groupLink",
  showGroupLink: false,
  siteIdsWithApiCall: [4, 1178, 80004],
};
