import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId1146: SiteIdOverrideConfig = {
  package: {
    heading: {
      lobNotSupported: "wizard.package.invalid.header.lobNotSupported.text",
    },
  },
  car: {
    iHaveDiscount: "wizard.car.having.brand.title",
  },
};
