import { CarVendorCouponTypes } from "./typings";

export interface CarVendorCouponMap {
  [vendor: string]: CarVendorCouponTypes;
}

export const carVendorCoupons: CarVendorCouponMap = {
  NO_PREFERENCE: { corporate: [], special: [] }, //no corporate codes when no preference is specified
  AC: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  AD: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.faststartID.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  AL: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountCode.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.alamoInsidersNumber.label",
        name: "odcd",
      },
    ],
  },
  ZI: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.AWDNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.wizardNumber.label",
        name: "odcd",
      },
    ],
  },
  ZD: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.BCDNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.fastBreakNumber.label",
        name: "odcd",
      },
    ],
  },
  ZR: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountCode.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.dollarExpressNumber.label",
        name: "odcd",
      },
    ],
  },
  EY: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountCode.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  ET: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.customerNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.enterprisePlus#.label",
        name: "odcd",
      },
    ],
  },
  EP: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.contractNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.privilegeNumber.label",
        name: "odcd",
      },
    ],
  },
  FX: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountCode.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  ZE: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.CDP.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  MW: { corporate: [], special: [] }, //no coupon codes for midway car rental
  ZL: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.nationalContractID.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.emeraldClubID.label",
        name: "odcd",
      },
    ],
  },
  NU: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateAccount.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  ZA: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountCode.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.paylessPerksNumber.label",
        name: "odcd",
      },
    ],
  },
  RO: { corporate: [], special: [] }, //no coupons for Routes Car Rental
  SX: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountCode.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  ZT: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountCode.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
      {
        labelToken: "wizard.car.couponCode.blueChipNumber.label",
        name: "odcd",
      },
    ],
  },
  SV: {
    corporate: [],
    special: [
      //no corporate coupon codes
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  SC: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountCode.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  GI: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  NC: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  RW: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
  PR: {
    corporate: [
      {
        labelToken: "wizard.car.couponCode.corporateDiscountNumber.label",
        name: "cdnu",
      },
    ],
    special: [
      {
        labelToken: "wizard.car.couponCode.discountRateCode.label",
        name: "rtcd",
      },
      {
        labelToken: "wizard.car.couponCode.discountCouponCode.label",
        name: "cpcd",
      },
    ],
  },
};
