import { ExternalLinkTabConfig } from "stores/wizard/config";

export const externalLinkTabConfig: ExternalLinkTabConfig = {
  navigation: {
    tabIcon: "people",
    tabTitleToken: "wizard.groups.tab.title.label",
  },
  tabURL: "https://taap.hotelplanner.com/Group-Rate/?kw=taap_group_button&ol=1",
  addLocaleToURL: true,
  elementId: "wizard-externalLinkTab-pwa",
  rfrrId: "",
};
