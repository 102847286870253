import { SiteIdOverrideConfig } from "stores/wizard/config";
import { defaultLocationValue_en } from "../../staticConfigs/thirdPartyPackage/thirdPartyPackageConfig";
import { defaultOriginsSiteId6, defaultDestinationsSiteId6 } from "./siteId6";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const siteId6_en_GB: SiteIdOverrideConfig = {
  package: {
    navigation: {
      tabTitleToken: "wizard.package.tab.title.variant.1.label",
    },
  },
  thirdPartyPackage: {
    origins: [defaultLocationValue_en, ...defaultOriginsSiteId6],
    destinations: [defaultLocationValue_en, ...defaultDestinationsSiteId6],
  },
};
