import { SiteIdOverrideConfigList } from "stores/wizard/config";
import {
  siteId1,
  siteId3,
  siteId4,
  siteId4_fr_CA,
  siteId6,
  siteId6_en_GB,
  siteId8,
  siteId9,
  siteId11,
  siteId11_nl_NL,
  siteId11_en_GB,
  siteId14,
  siteId15,
  siteId16,
  siteId17,
  siteId18,
  siteId6_de_DE,
  siteId20,
  siteId25,
  siteId27,
  siteId28,
  siteId29,
  siteId300000017,
  siteId70201,
  siteId80001,
  siteId80004,
  siteId61,
  siteId62,
  siteId63,
  siteId64,
  siteId65,
  siteId66,
  siteId67,
  siteId68,
  siteId69,
  siteId70,
  siteId71,
  siteId72,
  siteId72_de_CH,
  siteId72_fr_CH,
  siteId72_en_GB,
  siteId73,
  siteId100224,
  siteId100305,
  siteId100309,
  siteId100311,
  siteId100388,
  siteId100387,
  siteId100496,
  siteId10122006,
  siteId10122006_de_DE,
  siteId10122006_en_GB,
  siteId1146,
  siteId1178,
  siteId2003,
  siteId2030,
  siteId70125,
  siteId70129,
  siteId70205,
  siteId70150,
  siteId70151,
  siteId70403,
  siteId70472,
  siteId70473,
  siteId70463,
  siteId70420,
  siteId70406,
  siteId70801,
  siteId705010001,
  siteId705010002,
  siteId705010003,
  siteId705010004,
  siteId705010005,
  siteId705010006,
  siteId705010008,
  siteId705010011,
  siteId705010012,
  siteId705010013,
  siteId705010014,
  siteId705010015,
  siteId705010016,
  siteId705010018,
  siteId705010019,
  siteId70901,
  siteId70903,
  siteId70904,
  siteId70906,
  siteId70908,
  siteId70909,
  siteId70911,
  siteId70912,
  siteId70914,
  siteId70915,
  siteId70916,
  siteId70917,
  siteId70918,
  siteId70920,
  siteId70925,
  siteId70927,
  siteId70928,
  siteId70929,
  siteId70961,
  siteId70962,
  siteId70963,
  siteId70964,
  siteId70965,
  siteId70966,
  siteId70967,
  siteId70968,
  siteId70969,
  siteId70970,
  siteId70971,
  siteId70972,
  siteId70973,
  siteId70975,
  siteId1070901,
  siteId1070903,
  siteId1070904,
  siteId1070906,
  siteId1070908,
  siteId1070909,
  siteId1070911,
  siteId1070912,
  siteId1070914,
  siteId1070915,
  siteId1070916,
  siteId1070917,
  siteId1070918,
  siteId1070920,
  siteId1070925,
  siteId1070927,
  siteId1070928,
  siteId1070929,
  siteId1070961,
  siteId1070962,
  siteId1070963,
  siteId1070964,
  siteId1070965,
  siteId1070966,
  siteId1070967,
  siteId1070968,
  siteId1070969,
  siteId1070970,
  siteId1070971,
  siteId1070972,
  siteId1070973,
  siteId1070975,
  siteId70472_de_CH,
  siteId70472_fr_CH,
  siteId70472_en_GB,
  siteId500210004,
  siteId509010011,
  siteId500110007,
  siteId509010013,
  siteId500051,
  siteId509010009,
  siteId500056,
  siteId500052,
  siteId500088,
  siteId500066,
  siteId500101,
  siteId900188,
  siteId500113,
  siteId500086,
} from "stores/wizard/config/overrideConfigBySiteId/siteIds";
import { siteId70301 } from "./siteIds/siteId70301";
import { siteId100707 } from "./siteIds/siteId100707";
import { siteId500081 } from "./siteIds/siteId500081";
import { siteId500083 } from "./siteIds/siteId500083";
import { siteId500084 } from "./siteIds/siteId500084";
import { siteId500053 } from "./siteIds/siteId500053";

export const overrideConfigBySiteIdList: SiteIdOverrideConfigList = {
  1: {
    default: siteId1,
  },
  3: {
    default: siteId3,
  },
  4: {
    default: siteId4,
    fr_CA: siteId4_fr_CA,
  },
  6: {
    default: siteId6,
    de_DE: siteId6_de_DE,
    en_GB: siteId6_en_GB,
  },
  8: {
    default: siteId8,
  },
  9: {
    default: siteId9,
  },
  11: {
    default: siteId11,
    nl_NL: siteId11_nl_NL,
    en_GB: siteId11_en_GB,
  },
  14: {
    default: siteId14,
  },
  15: {
    default: siteId15,
  },
  16: {
    default: siteId16,
  },
  17: {
    default: siteId17,
  },
  18: {
    default: siteId18,
  },
  20: {
    default: siteId20,
  },
  25: {
    default: siteId25,
  },
  27: {
    default: siteId27,
  },
  28: {
    default: siteId28,
  },
  29: {
    default: siteId29,
  },
  61: {
    default: siteId61,
  },
  62: {
    default: siteId62,
  },
  63: {
    default: siteId63,
  },
  64: {
    default: siteId64,
  },
  65: {
    default: siteId65,
  },
  66: {
    default: siteId66,
  },
  67: {
    default: siteId67,
  },
  68: {
    default: siteId68,
  },
  69: {
    default: siteId69,
  },
  70: {
    default: siteId70,
  },
  71: {
    default: siteId71,
  },
  72: {
    default: siteId72,
    de_CH: siteId72_de_CH,
    fr_CH: siteId72_fr_CH,
    en_GB: siteId72_en_GB,
  },
  73: {
    default: siteId73,
  },
  70201: {
    default: siteId70201,
  },
  80001: {
    default: siteId80001,
  },
  80004: {
    default: siteId80004,
  },
  100707: {
    default: siteId100707,
  },
  100224: {
    default: siteId100224,
  },
  100305: {
    default: siteId100305,
  },
  100309: {
    default: siteId100309,
  },
  100311: {
    default: siteId100311,
  },
  100387: {
    default: siteId100387,
  },
  100388: {
    default: siteId100388,
  },
  100496: {
    default: siteId100496,
  },
  10122006: {
    default: siteId10122006,
    de_DE: siteId10122006_de_DE,
    en_GB: siteId10122006_en_GB,
  },
  1146: {
    default: siteId1146,
  },
  1178: {
    default: siteId1178,
  },
  2003: {
    default: siteId2003,
  },
  2030: {
    default: siteId2030,
  },
  70125: {
    default: siteId70125,
  },
  70129: {
    default: siteId70129,
  },
  70205: {
    default: siteId70205,
  },
  70150: {
    default: siteId70150,
  },
  70151: {
    default: siteId70151,
  },
  70301: {
    default: siteId70301,
  },
  70403: {
    default: siteId70403,
  },
  70473: {
    default: siteId70473,
  },
  70463: {
    default: siteId70463,
  },
  70420: {
    default: siteId70420,
  },
  70406: {
    default: siteId70406,
  },
  70801: {
    default: siteId70801,
  },
  705010001: {
    default: siteId705010001,
  },
  705010002: {
    default: siteId705010002,
  },
  705010003: {
    default: siteId705010003,
  },
  705010004: {
    default: siteId705010004,
  },
  705010005: {
    default: siteId705010005,
  },
  705010006: {
    default: siteId705010006,
  },
  705010008: {
    default: siteId705010008,
  },
  705010011: {
    default: siteId705010011,
  },
  705010012: {
    default: siteId705010012,
  },
  705010013: {
    default: siteId705010013,
  },
  705010014: {
    default: siteId705010014,
  },
  705010015: {
    default: siteId705010015,
  },
  705010016: {
    default: siteId705010016,
  },
  705010018: {
    default: siteId705010018,
  },
  705010019: {
    default: siteId705010019,
  },
  70901: {
    default: siteId70901,
  },
  70903: {
    default: siteId70903,
  },
  70904: {
    default: siteId70904,
  },
  70906: {
    default: siteId70906,
  },
  70908: {
    default: siteId70908,
  },
  70909: {
    default: siteId70909,
  },
  70911: {
    default: siteId70911,
  },
  70912: {
    default: siteId70912,
  },
  70914: {
    default: siteId70914,
  },
  70915: {
    default: siteId70915,
  },
  70916: {
    default: siteId70916,
  },
  70917: {
    default: siteId70917,
  },
  70918: {
    default: siteId70918,
  },
  70920: {
    default: siteId70920,
  },
  70925: {
    default: siteId70925,
  },
  70927: {
    default: siteId70927,
  },
  70928: {
    default: siteId70928,
  },
  70929: {
    default: siteId70929,
  },
  70961: {
    default: siteId70961,
  },
  70962: {
    default: siteId70962,
  },
  70963: {
    default: siteId70963,
  },
  70964: {
    default: siteId70964,
  },
  70965: {
    default: siteId70965,
  },
  70966: {
    default: siteId70966,
  },
  70967: {
    default: siteId70967,
  },
  70968: {
    default: siteId70968,
  },
  70969: {
    default: siteId70969,
  },
  70970: {
    default: siteId70970,
  },
  70971: {
    default: siteId70971,
  },
  70972: {
    default: siteId70972,
  },
  70973: {
    default: siteId70973,
  },
  70975: {
    default: siteId70975,
  },
  1070901: {
    default: siteId1070901,
  },
  1070903: {
    default: siteId1070903,
  },
  1070904: {
    default: siteId1070904,
  },
  1070906: {
    default: siteId1070906,
  },
  1070908: {
    default: siteId1070908,
  },
  1070909: {
    default: siteId1070909,
  },
  1070911: {
    default: siteId1070911,
  },
  1070912: {
    default: siteId1070912,
  },
  1070914: {
    default: siteId1070914,
  },
  1070915: {
    default: siteId1070915,
  },
  1070916: {
    default: siteId1070916,
  },
  1070917: {
    default: siteId1070917,
  },
  1070918: {
    default: siteId1070918,
  },
  1070920: {
    default: siteId1070920,
  },
  1070925: {
    default: siteId1070925,
  },
  1070927: {
    default: siteId1070927,
  },
  1070928: {
    default: siteId1070928,
  },
  1070929: {
    default: siteId1070929,
  },
  1070961: {
    default: siteId1070961,
  },
  1070962: {
    default: siteId1070962,
  },
  1070963: {
    default: siteId1070963,
  },
  1070964: {
    default: siteId1070964,
  },
  1070965: {
    default: siteId1070965,
  },
  1070966: {
    default: siteId1070966,
  },
  1070967: {
    default: siteId1070967,
  },
  1070968: {
    default: siteId1070968,
  },
  1070969: {
    default: siteId1070969,
  },
  1070970: {
    default: siteId1070970,
  },
  1070971: {
    default: siteId1070971,
  },
  1070972: {
    default: siteId1070972,
  },
  1070973: {
    default: siteId1070973,
  },
  1070975: {
    default: siteId1070975,
  },
  300000017: {
    default: siteId300000017,
  },
  70472: {
    default: siteId70472,
    en_GB: siteId70472_en_GB,
    de_CH: siteId70472_de_CH,
    fr_CH: siteId70472_fr_CH,
  },
  500210004: {
    default: siteId500210004,
  },
  509010011: {
    default: siteId509010011,
  },
  500110007: {
    default: siteId500110007,
  },
  509010013: {
    default: siteId509010013,
  },
  500051: {
    default: siteId500051,
  },
  509010009: {
    default: siteId509010009,
  },
  500056: {
    default: siteId500056,
  },
  500052: {
    default: siteId500052,
  },
  500081: {
    default: siteId500081,
  },
  500083: {
    default: siteId500083,
  },
  500084: {
    default: siteId500084,
  },
  500088: {
    default: siteId500088,
  },
  500053: {
    default: siteId500053,
  },
  500066: {
    default: siteId500066,
  },
  500101: {
    default: siteId500101,
  },
  900188: {
    default: siteId900188,
  },
  500113: {
    default: siteId500113,
  },
  500086: {
    default: siteId500086,
  },
};
