import {
  GlobalConfig,
  PackageConfig,
  LxConfig,
  VrConfig,
  CarConfig,
  HotelConfig,
  ThirdPartyPackageConfig,
  CruiseConfig,
  FlightConfig,
  ExternalLinkTabConfig,
} from "stores/wizard/config";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "src/components/shared/TravelersField";

export interface EssAdapterConfig {
  lob: string;
  client: string;
  features: string;
  maxResults: number;
  regionType: number;
  regionId?: string | number;
  dest: boolean;
  packageType?: string;
  latLong?: string;
  alternate?: boolean;
  trending?: boolean;
  historyDetail?: boolean;
}

export interface Location {
  origin?: LocationFieldConfig;
  destination?: DestinationFieldConfig;
}

export interface SubmitButton {
  locToken: string;
  locWarningsToken?: string;
  locWarningLinkToken?: string;
  locWarningsWithNumberToken?: string;
  locFirstWarningLinkToken?: string;
  subTextTokens?: {
    fh: string;
    fhc: string;
    fc: string;
    hc: string;
  };
}

export interface Form {
  submitButton: SubmitButton;
  action: string;
  actionPackage?: string;
  actionPropertySearch?: string;
}

interface Heading {
  fixedTitle: boolean;
  differentiatedTitles?: boolean;
  locTitleToken: string;
  locTitleDesktopTextToken?: string;
  lobSubtitleBreakPoint?: string;
  title?: string;
  visibleLobSubtitle?: boolean;
  locSubtitleTextToken?: string;
  locSubtitleLinkToken?: string;
  locSubtitleLinkUrl?: string;
  locSubtitleVariant?: string;
  hideLobTitle?: boolean;
}

export interface LocationFieldConfig {
  inputName: string;
  icon: string;
  labelToken: string;
  noValueLabelToken?: string;
  withValueLabelToken?: string;
  typeaheadLabelToken?: string;
  typeaheadEmptyResultPlaceholder?: string;
  typeaheadCurrentLocationEnabled?: boolean;
  defaultToUserLocation?: boolean;
  currentLocationInputName?: string;
  pwaDialogQueryParam: string;
  essAdapterConfig: EssAdapterConfig;
  queryParams?: LocationQueryParamConfig;
  invalidLocationMessageKey: string;
}

export interface CarLocationFieldConfig extends LocationFieldConfig {
  pickUpDropOffLabelToken: string;
  pickUpLocationLabelToken: string;
  differentDropOffToken: string;
}

export interface DestinationFieldConfig extends LocationFieldConfig {
  invalidOriginSameAsDestinationKey?: string;
}

interface DateConfig {
  labelToken: string;
}

export interface DateFieldConfig {
  cutoffDate?: string;
  ISOSubmissionSupport: boolean;
  format: string;
  submissionFormat: string;
  daysBookableInAdvance: number;
  midnightBookingOffset: number;
  softPackage?: {
    daysBookableInAdvance: number;
  };
  maxDaysRange: number;
  invalidStartDateMessageToken: string;
  invalidEndDateMessageToken: string;
  invalidRequiredEndDateMessageToken?: string;
  invalidMaxDatesRangeMessageToken: string;
  invalidDateRangeEndBeforeStart?: string;
  invalidStartDateOutOfRangeMessageToken: string;
  invalidEndDateOutOfRangeMessageToken: string;
  invalidStartTimeMessageToken?: string;
  invalidEndTimeMessageToken?: string;
  invalidOnewayHotelStartDateMessageToken?: string;
  start: DateConfig;
  end: DateConfig;
  queryParam: DateQueryParamConfig;
  dialogId: string;
  defaultOffsetDates?: {
    start: number;
    end: number;
  };
}

export interface TravelersFieldConfig {
  icon: string;
  labelToken: string;
  dialogTitleToken: string;
  dialogCancelLabelToken: string;
  adultsCaptionToken?: string;
  childrenCaptionToken: string;
  infantsCaptionToken: string;
  youngestChildAgeTextToken: string;
  youngestInfantAgeTextToken: string;
  invalidLessThanNTravelersMessageToken: string;
  invalidUnattendedInfantOnLapMessageToken: string;
  invalidNumberOfInfantPerTravelerMessageToken?: string;
  invalidChildValueMessageToken?: string;
  invalidChildrenValuesMessageToken?: string;
  invalidInfantValueMessageToken?: string;
  invalidInfantsValuesMessageToken?: string;
  validateChildInfantValues?: boolean;
  travelersToken: string;
  roomsGuestsToken: string;
  minBookableTravelers: number;
  defaultTravelers?: number;
  maxBookableTravelers: number;
  maxTravelersPerRoom?: number;
  minChildAge: number;
  maxChildAge: number;
  minInfantAge: number;
  maxInfantAge: number;
  minInfantPerTraveler?: number;
  minAdultsPerRoom: number;
  maxAdultsPerRoom: number;
  minChildrenPerRoom: number;
  maxChildrenPerRoom: number;
  minInfantsPerRoom: number;
  maxInfantsPerRoom: number;
  maxRooms: number;
  withAges: boolean;
  withRooms: boolean;
  withFlight: boolean;
  withInfants: boolean;
  hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE;
  noRehydratationValues?: NoRehydratationValues;
}

export interface Navigation {
  tabIcon: string;
  tabTitleToken: string;
  subTabTitleToken?: string;
}

export interface LocationQueryParamConfig {
  regionId: string;
  latLong?: string;
  iataCode?: string;
  countryCode?: string;
}

export interface DateQueryParamConfig {
  start: string;
  end: string;
}

export interface MultiLOBWizardConfig {
  global: GlobalConfig;
  package: PackageConfig;
  lx: LxConfig;
  vr: VrConfig;
  car: CarConfig;
  hotel: HotelConfig;
  flight: FlightConfig;
  thirdPartyPackage: ThirdPartyPackageConfig;
  cruise: CruiseConfig;
  externalLinkTab: ExternalLinkTabConfig;
}

export interface LOBConfig extends TABConfig {
  location: Location;
  date: DateFieldConfig;
  form: Form;
  heading: Heading;
  hotelDate?: DateFieldConfig;
}

export interface TABConfig {
  navigation: Navigation;
  elementId?: string;
}

interface NoRehydratationValues {
  name: string;
  numOfTravelers: number;
}

export type VrPropertyType =
  | "VACATION_HOME"
  | "CONDO"
  | "APARTMENT"
  | "APART_HOTEL"
  | "CABIN"
  | "CHALET"
  | "CONDO_RESORT"
  | "COTTAGE"
  | "HOUSE_BOAT"
  | "RESIDENCE"
  | "VILLA"
  | "CARAVAN_PARK";

export const VR_PROPERTY_TYPES_ALL: VrPropertyType[] = [
  "VACATION_HOME",
  "CONDO",
  "APARTMENT",
  "APART_HOTEL",
  "CABIN",
  "CHALET",
  "CONDO_RESORT",
  "COTTAGE",
  "HOUSE_BOAT",
  "RESIDENCE",
  "VILLA",
  "CARAVAN_PARK",
];

export type DayOfWeek = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";

/**
 * FlexManager Template Module Attributes offset dates for shared FTNode Options
 */
export interface OffsetDatesOptions {
  adjustOffsetsToDayOfWeek?: DayOfWeek;
  startDateOffset?: number;
  endDateOffset?: number;
  startDate?: string;
  endDate?: string;
}
