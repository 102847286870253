import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId1178: SiteIdOverrideConfig = {
  package: {
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.text",
    },
  },
  car: {
    iHaveDiscount: "wizard.car.having.brand.title",
  },
  thirdPartyPackage: {
    date: {
      cutoffDate: "2023-01-01",
    },
  },
};
