import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId900188: SiteIdOverrideConfig = {
  flight: {
    travelers: {
      invalidLessThanNTravelersMessageToken: "wizard.travelers.error.invalidNumberOfTravelers.newtext",
    },
  },
  car: {
    date: {
      maxDaysRange: 329,
    },
    disableVendorCouponCode: true,
    showVendorOptions: false,
  },
};
