import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId1070972: SiteIdOverrideConfig = {
  global: {
    packageWith3PP: true,
  },
  hotel: {
    useTravelAgentSearchWizardConfig: true,
    packageRatesTermsUrl: "/static/default/default/taap/contracts/hpr/CH.HPR.012021.pdf",
    navigation: {
      tabTitleToken: "wizard.properties.tab.title.label",
    },
    heading: {
      hideLobTitle: true,
      locTitleToken: "wizard.taap.hotel.tab.title.label",
    },
  },
  package: {
    lobLabels: {
      hotelsLabel: "wizard.properties.tab.title.label",
    },
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.taap.text",
    },
  },
  car: {
    driveAgeCheckbox: true,
    includeGT: false,
    gtConfig: {
      location: {
        destination: {
          labelToken: "wizard.gt.propertyName.label",
        },
      },
    },
  },
  lx: {
    navigation: {
      tabTitleToken: "wizard.activities.tab.title.label",
    },
    location: {
      destination: {
        labelToken: "wizard.activitiesIn.thingsToDoIn.label",
      },
    },
  },
  thirdPartyPackage: {
    defaultPackageForm: "all-inclusive",
    duration: {
      queryParam: "dur",
    },
    date: {
      queryParam: {
        start: "ddate",
        end: "rdate",
      },
    },
    offersWithTransfer: {
      isVisible: true,
    },
    form: {
      action: "//badeferien.expedia.ch",
    },
    origins: [
      {
        code: "any",
        name: "beliebig",
      },
      {
        code: "AGB",
        name: "Augsburg (D)",
      },
      {
        code: "BSL",
        name: "Basel Euro Airport",
      },
      {
        code: "MLH",
        name: "Basel-Mulhouse",
      },
      {
        code: "BRN",
        name: "Bern-Belp",
      },
      {
        code: "FRA",
        name: "Frankfurt am Main (D)",
      },
      {
        code: "FDH",
        name: "Friedrichshafen (D)",
      },
      {
        code: "GVA",
        name: "Genf",
      },
      {
        code: "GRZ",
        name: "Graz (A)",
      },
      {
        code: "INN",
        name: "Innsbruck (A)",
      },
      {
        code: "FKB",
        name: "Karlsruhe (D)",
      },
      {
        code: "KLU",
        name: "Klagenfurt (A)",
      },
      {
        code: "LNZ",
        name: "Linz (A)",
      },
      {
        code: "LUG",
        name: "Lugano",
      },
      {
        code: "MIL",
        name: "Mailand (IT)",
      },
      {
        code: "FMM",
        name: "Memmingen (D)",
      },
      {
        code: "MUC",
        name: "München (D)",
      },
      {
        code: "NUE",
        name: "Nürnberg (D)",
      },
      {
        code: "SZG",
        name: "Salzburg (A)",
      },
      {
        code: "ACH",
        name: "St. Gallen",
      },
      {
        code: "STR",
        name: "Stuttgart (D)",
      },
      {
        code: "VIE",
        name: "Wien (A)",
      },
      {
        code: "ZRH",
        name: "Zürich",
      },
    ],
    destinations: [
      {
        code: "any",
        name: "beliebig",
      },
      {
        code: "10011",
        name: "Afrika",
      },
      {
        code: "10010",
        name: "Ägypten",
      },
      {
        code: "10004:434",
        name: "Antalya",
      },
      {
        code: "10017",
        name: "Asien",
      },
      {
        code: "10000",
        name: "Balearen",
      },
      {
        code: "10017:311",
        name: "Bali &  Indonesien",
      },
      {
        code: "20014",
        name: "Benelux",
      },
      {
        code: "10030:20006",
        name: "Bulgarien",
      },
      {
        code: "10029",
        name: "Deutschland",
      },
      {
        code: "10013",
        name: "Dominikanische Republik",
      },
      {
        code: "10021",
        name: "Frankreich",
      },
      {
        code: "10001:832",
        name: "Fuerteventura",
      },
      {
        code: "10001:345",
        name: "Gran Canaria",
      },
      {
        code: "10006",
        name: "Griechenland Festland",
      },
      {
        code: "10005",
        name: "Griechische Inseln",
      },
      {
        code: "30023",
        name: "Großbritannien &  Irland",
      },
      {
        code: "10010:350",
        name: "Hurghada",
      },
      {
        code: "10036",
        name: "Indischer Ozean",
      },
      {
        code: "10007",
        name: "Italien, Malta",
      },
      {
        code: "10014:247",
        name: "Jamaika",
      },
      {
        code: "10024",
        name: "Kanada",
      },
      {
        code: "10001",
        name: "Kanaren",
      },
      {
        code: "10014",
        name: "Karibik",
      },
      {
        code: "10005:616",
        name: "Kreta",
      },
      {
        code: "10030:20007",
        name: "Kroatien, Ungarn",
      },
      {
        code: "10001:676",
        name: "Lanzarote",
      },
      {
        code: "10002:639",
        name: "Madeira",
      },
      {
        code: "10036:342",
        name: "Malediven",
      },
      {
        code: "10000:35",
        name: "Mallorca",
      },
      {
        code: "10036:143",
        name: "Mauritius",
      },
      {
        code: "10016",
        name: "Mexiko",
      },
      {
        code: "10037",
        name: "Mittelamerika",
      },
      {
        code: "10028",
        name: "Mitteleuropa",
      },
      {
        code: "10026",
        name: "Naher Osten",
      },
      {
        code: "10011:453",
        name: "Namibia",
      },
      {
        code: "10022",
        name: "Nordeuropa",
      },
      {
        code: "20015",
        name: "Österreich",
      },
      {
        code: "10030:20018",
        name: "Osteuropa",
      },
      {
        code: "10002",
        name: "Portugal",
      },
      {
        code: "10033",
        name: "Rundreisen",
      },
      {
        code: "20016",
        name: "Schweiz",
      },
      {
        code: "10010:351",
        name: "Sharm El Sheikh",
      },
      {
        code: "10003",
        name: "Spanien",
      },
      {
        code: "10017:361",
        name: "Sri Lanka",
      },
      {
        code: "30032",
        name: "Südafrika",
      },
      {
        code: "10023",
        name: "Südamerika",
      },
      {
        code: "10001:56",
        name: "Teneriffa",
      },
      {
        code: "10027",
        name: "Thailand",
      },
      {
        code: "10008",
        name: "Tunesien, Marokko",
      },
      {
        code: "10004",
        name: "Türkei",
      },
      {
        code: "10015",
        name: "USA",
      },
      {
        code: "10020",
        name: "Vereinigte Arabische Emirate",
      },
      {
        code: "10019",
        name: "Zypern",
      },
    ],
    durations: [
      {
        value: "any",
        text: "any",
      },
      {
        value: "exact",
        text: "as stated",
      },
      {
        value: "7",
        text: "1 week",
      },
      {
        value: "14",
        text: "2 weeks",
      },
      {
        value: "21",
        text: "3 weeks",
      },
      {
        value: "1,4",
        text: "1-4 days",
      },
      {
        value: "5,8",
        text: "5-8 days",
      },
      {
        value: "9,12",
        text: "9-12 days",
      },
      {
        value: "13,15",
        text: "13-15 days",
      },
      {
        value: "16,22",
        text: "16-22 days",
      },
      {
        value: "1",
        text: "1 day",
      },
      {
        value: "2",
        text: "2 days",
      },
      {
        value: "3",
        text: "3 days",
      },
      {
        value: "4",
        text: "4 days",
      },
      {
        value: "5",
        text: "5 days",
      },
      {
        value: "6",
        text: "6 days",
      },
      {
        value: "8",
        text: "8 days",
      },
      {
        value: "9",
        text: "9 days",
      },
      {
        value: "10",
        text: "10 days",
      },
      {
        value: "11",
        text: "11 days",
      },
      {
        value: "12",
        text: "12 days",
      },
      {
        value: "13",
        text: "13 days",
      },
      {
        value: "15",
        text: "15 days",
      },
      {
        value: "16",
        text: "16 days",
      },
      {
        value: "17",
        text: "17 days",
      },
      {
        value: "18",
        text: "18 days",
      },
      {
        value: "19",
        text: "19 days",
      },
      {
        value: "20",
        text: "20 days",
      },
      {
        value: "21",
        text: "21 days",
      },
      {
        value: "22",
        text: "22 days",
      },
      {
        value: "23,100",
        text: "> 22 days",
      },
    ],
  },
};
