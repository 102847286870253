import { SiteIdOverrideConfig } from "stores/wizard/config";
import { timeValues24HoursFormat } from "src/components/shared/TimeDropdown/config/time/timeValues";

export const siteId70971: SiteIdOverrideConfig = {
  package: {
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.taap.text",
    },
  },
  car: {
    location: {
      destination: {
        inputName: "returnName",
      },
      origin: {
        inputName: "pickupName",
      },
    },
    date: {
      queryParam: {
        start: "",
        end: "",
      },
    },
    form: {
      action: "https://car-rental.expedia.com.vn/{locale}-vn/book#/vehicles",
    },
    timeValues: timeValues24HoursFormat,
    defaultShowingTime: "10:30",
    isCarTrawler: true,
    includeGT: false,
    showVendorOptions: false,
    clientID: 312190,
    residenceID: "VN",
    gtConfig: {
      location: {
        destination: {
          labelToken: "wizard.gt.propertyName.label",
        },
      },
    },
  },
  hotel: {
    useTravelAgentSearchWizardConfig: true,
    packageRatesTermsUrl: "/static/default/default/taap/contracts/hpr/VN.HPR.012021.pdf",
    navigation: {
      tabTitleToken: "wizard.properties.tab.title.label",
    },
    heading: {
      hideLobTitle: false,
      locTitleToken: "wizard.taap.hotel.tab.title.label",
    },
  },
};
