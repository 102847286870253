import { HotelConfig } from "stores/wizard/config";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "src/components/shared/TravelersField";

const travelersConfig = {
  icon: "person",
  labelToken: "wizard.roomPicker.travelers.title",
  dialogTitleToken: "wizard.roomPicker.travelers.title",
  dialogCancelLabelToken: "wizard.roomPicker.closeButton.a11yLabel",
  childrenCaptionToken: "wizard.roomPicker.children.caption",
  infantsCaptionToken: "",
  youngestChildAgeTextToken: "wizard.roomPicker.youngestChildAge.text",
  youngestInfantAgeTextToken: "",
  invalidChildValueMessageToken: "wizard.travelers.warning.childAgeAllProvided.text",
  invalidChildrenValuesMessageToken: "wizard.travelers.warning.childrenAgesAllProvided.text",
  invalidInfantValueMessageToken: "wizard.travelers.warning.infantAgeAllProvided.text",
  invalidInfantsValuesMessageToken: "wizard.travelers.warning.infantAgesAllProvided.text",
  invalidLessThanNTravelersMessageToken: "wizard.travelers.warning.invalidNumberOfTravelers.text",
  invalidUnattendedInfantOnLapMessageToken: "",
  travelersToken: "wizard.roomPicker.travelers",
  roomsGuestsToken: "wizard.roomPicker.roomsGuests",
  minBookableTravelers: 1,
  defaultTravelers: 2,
  maxBookableTravelers: 14,
  minChildAge: 0,
  maxChildAge: 17,
  minInfantAge: 0,
  maxInfantAge: 1,
  minAdultsPerRoom: 1,
  maxAdultsPerRoom: 14,
  minChildrenPerRoom: 0,
  maxChildrenPerRoom: 6,
  minInfantsPerRoom: 0,
  maxInfantsPerRoom: 0,
  maxRooms: 8,
  validateChildInfantValues: true,
  withRooms: true,
  withAges: true,
  withFlight: false,
  withInfants: false,
  hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.HOTEL_SEARCH,
};

export const hotelConfig: HotelConfig = {
  navigation: {
    tabIcon: "lob_hotels",
    tabTitleToken: "wizard.hotel.tab.title.label",
  },
  location: {
    destination: {
      inputName: "destination",
      icon: "place",
      invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
      invalidOriginSameAsDestinationKey: "",
      labelToken: "wizard.hotel.destination.label",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.hotel",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.hotel",
      pwaDialogQueryParam: "destination-hotel",
      essAdapterConfig: {
        lob: "HOTELS",
        client: "Homepage",
        features: "ta_hierarchy|postal_code|google|consistent_display",
        maxResults: 8,
        regionType: 2047,
        dest: true,
      },
      queryParams: {
        regionId: "regionId",
        latLong: "latLong",
      },
    },
    origin: {
      inputName: "origin",
      icon: "place",
      invalidLocationMessageKey: "wizard.origin.warning.invalidOrigin.text",
      labelToken: "wizard.flight.origin.label",
      typeaheadLabelToken: "wizard.typeahead.placeholder.title.package.origin",
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.flight",
      pwaDialogQueryParam: "origin-flight",
      essAdapterConfig: {
        lob: "FLIGHTS",
        client: "Homepage",
        features: "nearby_airport|ta_hierarchy|consistent_display",
        maxResults: 8,
        regionType: 351,
        dest: false,
      },
    },
  },
  locationFlight: {
    origin: {
      inputName: "",
      icon: "",
      invalidLocationMessageKey: "",
      labelToken: "",
      typeaheadLabelToken: "wizard.flight.origin.placeholder",
      pwaDialogQueryParam: "",
      essAdapterConfig: {
        lob: "",
        client: "",
        features: "",
        maxResults: 8,
        regionType: 0,
        dest: false,
      },
    },
  },
  date: {
    ISOSubmissionSupport: true,
    format: "yyyy-MM-dd",
    submissionFormat: "yyyy-MM-dd",
    daysBookableInAdvance: 500,
    softPackage: {
      daysBookableInAdvance: 331,
    },
    midnightBookingOffset: 4,
    maxDaysRange: 28,
    invalidStartDateMessageToken: "wizard.datePicker.warning.invalidFromDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.invalidToDate.text",
    invalidRequiredEndDateMessageToken: "wizard.datePicker.warning.endDateRequired.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "",
    invalidEndDateOutOfRangeMessageToken: "",
    start: {
      labelToken: "datePicker.fromDate.label",
    },
    end: {
      labelToken: "datePicker.toDate.label",
    },
    queryParam: {
      start: "startDate",
      end: "endDate",
    },
    defaultOffsetDates: {
      start: 14,
      end: 15,
    },
    dialogId: "check-in-out",
  },
  travelers: travelersConfig,
  travelersPackage: {
    ...travelersConfig,
    maxBookableTravelers: 6,
    maxRooms: 6,
  },
  form: {
    action: "/Hotel-Search",
    actionPackage: "/flexibleshopping",
    actionPropertySearch: "/Property-Search",
    submitButton: {
      locToken: "wizard.submit.label",
      locWarningsToken: "wizard.submit.warnings",
      locWarningLinkToken: "wizard.submit.warning.link",
      locWarningsWithNumberToken: "wizard.submit.warningsWithNumber",
      locFirstWarningLinkToken: "wizard.submit.warnings.link",
    },
  },
  heading: {
    fixedTitle: false,
    lobSubtitleBreakPoint: "off",
    locTitleToken: "wizard.mobile.label.hotel",
  },
  desktopAutoOpenCalendar: false,
  elementId: "wizard-hotel-pwa",
  enableAddCar: false,
  enableAddFlight: false,
  enablePackageRates: false,
  percentSave: 20,
  packageRatesTermsUrl: "",
  includeFiltersHotelPWAWizard: false,
  enableMultiplePlaces: false,
  useDialogTextWithoutCar: false,
  showDestinationField: true,
  siteIdsWithoutSoftPackages: [],
  hotelName: "",
  hotelID: "",
  hotelChainID: "",
  multiHotelIDs: undefined,
  isBiasedWizardEmpty: true,
  useTravelAgentSearchWizardConfig: false,
  travelerType: undefined,
  enableHotelsPWPToggle: false,
  payWithPointsCheckedToken: "wizard.hotel.pwpToggle.points",
  payWithCashCheckedToken: "wizard.hotel.pwpToggle.cash",
  packageRatesIncludedToken: "wizard.hotel.pkgToggle.included",
  packageRatesExcludedToken: "wizard.hotel.pkgToggle.excluded",
  paymentTypePreset: "",
};
