import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId500081: SiteIdOverrideConfig = {
  flight: {
    cabinClass: {
      econonomy: "econonomy",
      premiumEconomy: "premiumEconomy",
      business: "business",
    },
  },
};
