import { SiteIdOverrideConfig } from "stores/wizard/config";
import { destinationsAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/destinationsAu";
import { departurePortsAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/departurePortsAu";
import { cruisesLineAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/cruisesLineAu";
import { durationsAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/durationsAu";
import { dateRangesAu } from "src/components/flexComponents/WizardCruisePWA/components/customData/dateRangesAu";

export const siteId1070925: SiteIdOverrideConfig = {
  global: {
    heroImageSrc: "https://a.travel-assets.com/travel-assets-manager/cmct-2135/AU_NZ_Autumn_Newzealand_1400x600.jpg",
  },
  car: {
    includeGT: false,
    gtConfig: {
      location: {
        destination: {
          labelToken: "wizard.gt.propertyName.label",
        },
      },
    },
  },
  hotel: {
    useTravelAgentSearchWizardConfig: true,
    packageRatesTermsUrl: "/static/default/default/taap/contracts/hpr/AU.HPR.012021.pdf",
    navigation: {
      tabTitleToken: "wizard.properties.tab.title.label",
    },
    heading: {
      hideLobTitle: true,
      locTitleToken: "wizard.taap.hotel.tab.title.label",
    },
  },
  lx: {
    navigation: {
      tabTitleToken: "wizard.activities.tab.title.label",
    },
    location: {
      destination: {
        labelToken: "wizard.activitiesIn.thingsToDoIn.label",
      },
    },
  },
  package: {
    lobLabels: {
      hotelsLabel: "wizard.properties.tab.title.label",
    },
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.taap.text",
    },
  },
  cruise: {
    date: {
      start: {
        labelToken: "wizard.cruise3pp.sailingDate.label",
      },
      queryParam: {
        start: "date",
      },
    },
    displayWizard3pp: true,
    wizard3pp: {
      destinations: destinationsAu,
      departurePorts: departurePortsAu,
      durations: durationsAu,
      cruiseLines: cruisesLineAu,
      actionUrl: "https://cruise.expedia.com.au/cruises/cruisedefault2.jsp",
      queryParam: {
        duration: "dur",
        destination: "destination",
        cruiseLine: "cruiseline",
        departure: "dport",
      },
      dateRanges: dateRangesAu,
    },
    heading: {
      lobSubtitleBreakPoint: "on",
      visibleLobSubtitle: true,
      locSubtitleVariant: "1800 030 501",
    },
  },
};
