import { SiteIdOverrideConfig } from "stores/wizard/config";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const siteId4_fr_CA: SiteIdOverrideConfig = {
  cruise: {
    heading: {
      locSubtitleVariant: "1 888 444-1695",
    },
  },
  thirdPartyPackage: {
    duration: {
      daysTextApi: "jours",
    },
    navigation: {
      tabTitleToken: "wizard.package.tab.title.variant.1.label",
    },
  },
};
