import { CarConfig } from "stores/wizard/config";
import { carVendorCoupons } from "./carVendorCoupons";
import { CarsVendorCodes } from "src/components/flexComponents/WizardCarPWA/components/vendorOptions/CarsVendorCodes";
import { locKeys } from "components/flexComponents/WizardCarPWA/components/l10n";
import { timeValues } from "src/components/shared/TimeDropdown/config";
import { gtConfig } from "./gt/gtConfig";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "src/components/shared/TravelersField";
import { CarTypes } from "src/components/flexComponents/WizardCarPWA/components/vendorOptions/CarTypes";

const {
  filledDropOffLocationLabel,
  dropOffLocationLabel,
  dropOffLocationPlaceholder,
  pickUpLocationLabel,
  pickUpDropOffLabel,
  pickUpLocationPlaceholder,
  pickUpDateLabel,
  dropOffDateLabel,
  differentDropOff,
  noPreference,
  aceRentACarCompany,
  thriftyCarRentalCompany,
  sixtCompany,
  uSaveCompany,
  silvercar,
  routesCarRental,
  paylessCompany,
  nuCarCompany,
  nationalCarRentalCompany,
  midwayCarRentalCompany,
  hertzCompany,
  foxRentalCarsCompany,
  europcarCompany,
  advantageRentACarCompany,
  alamoRentACarCompany,
  avisCompany,
  budgetCompany,
  dollarRentACarCompany,
  economyRentACarCompany,
  enterpriseCompany,
  greenMotionCompany,
  nextCarCompany,
  rentAWreckCompany,
  pricelessCompany,
  midsize,
  compact,
  economy,
  fullsize,
  luxury,
  minivan,
  other,
  pickup,
  premium,
  sportsCar,
  standard,
  SUV,
  van,
  carType,
} = locKeys;

const travelersConfig = {
  icon: "person",
  labelToken: "wizard.roomPicker.travelers.title",
  dialogTitleToken: "wizard.roomPicker.travelers.title",
  dialogCancelLabelToken: "wizard.roomPicker.closeButton.a11yLabel",
  childrenCaptionToken: "wizard.roomPicker.children.withInfants.caption",
  infantsCaptionToken: "wizard.roomPicker.infants.caption",
  youngestChildAgeTextToken: "",
  youngestInfantAgeTextToken: "wizard.roomPicker.youngestChildAge.text",
  invalidChildValueMessageToken: "wizard.travelers.warning.childAgeAllProvided.text",
  invalidChildrenValuesMessageToken: "wizard.travelers.warning.childrenAgesAllProvided.text",
  invalidInfantValueMessageToken: "wizard.travelers.warning.infantAgeAllProvided.text",
  invalidInfantsValuesMessageToken: "wizard.travelers.warning.infantAgesAllProvided.text",
  invalidLessThanNTravelersMessageToken: "wizard.travelers.warning.invalidNumberOfTravelers.text",
  invalidUnattendedInfantOnLapMessageToken: "wizard.travelers.warning.unattendedInfantOnLap.text",
  travelersToken: "wizard.roomPicker.travelers",
  roomsGuestsToken: "wizard.roomPicker.roomsGuests",
  minBookableTravelers: 1,
  defaultTravelers: 2,
  maxBookableTravelers: 6,
  minChildAge: 2,
  maxChildAge: 17,
  minInfantAge: 0,
  maxInfantAge: 1,
  minAdultsPerRoom: 1,
  maxAdultsPerRoom: 6,
  minChildrenPerRoom: 0,
  maxChildrenPerRoom: 6,
  minInfantsPerRoom: 0,
  maxInfantsPerRoom: 6,
  maxRooms: 1,
  validateChildInfantValues: false,
  withRooms: false,
  withAges: true,
  withFlight: false,
  withInfants: true,
  hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE.GROUND_TRANSPORT_SEARCH,
};

export const carConfig: CarConfig = {
  navigation: {
    tabIcon: "lob_cars",
    tabTitleToken: "wizard.car.tab.title.label",
  },
  location: {
    destination: {
      inputName: "loc2",
      icon: "place",
      labelToken: dropOffLocationLabel,
      noValueLabelToken: "wizard.car.dropOffLocation.placeholder",
      withValueLabelToken: filledDropOffLocationLabel,
      typeaheadLabelToken: dropOffLocationPlaceholder,
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.car",
      invalidLocationMessageKey: "wizard.destination.warning.invalidDestination.text",
      invalidOriginSameAsDestinationKey: "",
      pwaDialogQueryParam: "destination-car",
      essAdapterConfig: {
        lob: "CARS",
        client: "Homepage",
        features: "postal_code|consistent_display|cars_rental|carsclickpopularity",
        maxResults: 8,
        regionType: 1583,
        dest: false,
      },
    },
    origin: {
      inputName: "locn",
      icon: "place",
      labelToken: pickUpDropOffLabel,
      pickUpLocationLabelToken: pickUpLocationLabel,
      typeaheadLabelToken: pickUpLocationPlaceholder,
      pickUpDropOffLabelToken: pickUpDropOffLabel,
      differentDropOffToken: differentDropOff,
      typeaheadEmptyResultPlaceholder: "wizard.typeahead.placeholder.emptyResultPlaceholder.car",
      invalidLocationMessageKey: "wizard.origin.warning.invalidOrigin.text",
      pwaDialogQueryParam: "origin-car",
      essAdapterConfig: {
        lob: "CARS",
        client: "Homepage",
        features: "postal_code|consistent_display|cars_rental|carsclickpopularity",
        maxResults: 8,
        regionType: 1583,
        dest: true,
      },
    },
  },
  date: {
    ISOSubmissionSupport: false,
    format: "yyyy-MM-dd",
    submissionFormat: "dd-MM-yyyy",
    daysBookableInAdvance: 329,
    midnightBookingOffset: 4,
    maxDaysRange: 30,
    invalidStartDateMessageToken: "wizard.datePicker.warning.invalidPickupDate.text",
    invalidEndDateMessageToken: "wizard.datePicker.warning.invalidDropoffDate.text",
    invalidMaxDatesRangeMessageToken: "wizard.datePicker.warning.maxDatesRange.text",
    invalidStartDateOutOfRangeMessageToken: "",
    invalidEndDateOutOfRangeMessageToken: "",
    invalidStartTimeMessageToken: "wizard.time.pickup.invalid",
    invalidEndTimeMessageToken: "",
    start: {
      labelToken: pickUpDateLabel,
    },
    end: {
      labelToken: dropOffDateLabel,
    },
    queryParam: {
      start: "date1",
      end: "date2",
    },
    dialogId: "pick-up-drop-off",
    defaultOffsetDates: {
      start: 14,
      end: 15,
    },
  },
  timeFormats: {
    timeFormat12Hour: false,
  },
  form: {
    action: "/carsearch",
    submitButton: {
      locToken: "wizard.submit.label",
      locWarningsToken: "wizard.submit.warnings",
      locWarningLinkToken: "wizard.submit.warning.link",
      locWarningsWithNumberToken: "wizard.submit.warningsWithNumber",
      locFirstWarningLinkToken: "wizard.submit.warnings.link",
    },
  },
  clientID: 0,
  residenceID: "",
  timeValues,
  defaultShowingTime: "1030AM",
  age: 30,
  minRangeAge: 30,
  maxRangeAge: 70,
  driveAgeCheckbox: false,
  AARPCheckbox: false,
  showVendorOptions: true,
  isCarTrawler: false,
  invalidDriversAgeMessageToken: "wizard.car.driverAge.input.invalidText",
  heading: {
    fixedTitle: false,
    lobSubtitleBreakPoint: "off",
    locTitleToken: "wizard.mobile.label.car",
  },
  disableVendorCouponCode: false,
  iHaveDiscount: "wizard.car.having.discount",

  vendors: {
    NO_PREFERENCE: {
      vendorCode: CarsVendorCodes.NO_PREFERENCE,
      couponInfo: carVendorCoupons.NO_PREFERENCE,
      locKey: noPreference,
      includeInDropdown: true,
    },
    AC: {
      vendorCode: CarsVendorCodes.ACE_RENT_A_CAR,
      couponInfo: carVendorCoupons.AC,
      locKey: aceRentACarCompany,
      includeInDropdown: true,
    },
    AD: {
      vendorCode: CarsVendorCodes.ADVANTAGE_RENT_A_CAR,
      couponInfo: carVendorCoupons.AD,
      locKey: advantageRentACarCompany,
      includeInDropdown: true,
    },
    AL: {
      vendorCode: CarsVendorCodes.ALAMO_RENT_A_CAR,
      couponInfo: carVendorCoupons.AL,
      locKey: alamoRentACarCompany,
      includeInDropdown: true,
    },
    ZI: {
      vendorCode: CarsVendorCodes.AVIS,
      couponInfo: carVendorCoupons.ZI,
      locKey: avisCompany,
      includeInDropdown: true,
    },
    ZD: {
      vendorCode: CarsVendorCodes.BUDGET,
      couponInfo: carVendorCoupons.ZD,
      locKey: budgetCompany,
      includeInDropdown: true,
    },
    ZR: {
      vendorCode: CarsVendorCodes.DOLLAR_RENT_A_CAR,
      couponInfo: carVendorCoupons.ZR,
      locKey: dollarRentACarCompany,
      includeInDropdown: true,
    },
    EY: {
      vendorCode: CarsVendorCodes.ECONOMY_RENT_A_CAR,
      couponInfo: carVendorCoupons.EY,
      locKey: economyRentACarCompany,
      includeInDropdown: true,
    },
    ET: {
      vendorCode: CarsVendorCodes.ENTERPRISE,
      couponInfo: carVendorCoupons.ET,
      locKey: enterpriseCompany,
      includeInDropdown: true,
    },
    EP: {
      vendorCode: CarsVendorCodes.EUROPCAR,
      couponInfo: carVendorCoupons.EP,
      locKey: europcarCompany,
      includeInDropdown: true,
    },
    FX: {
      vendorCode: CarsVendorCodes.FOX_RENT_A_CAR,
      couponInfo: carVendorCoupons.FX,
      locKey: foxRentalCarsCompany,
      includeInDropdown: true,
    },
    ZE: {
      vendorCode: CarsVendorCodes.HERTZ,
      couponInfo: carVendorCoupons.ZE,
      locKey: hertzCompany,
      includeInDropdown: true,
    },
    MW: {
      vendorCode: CarsVendorCodes.MIDWAY_CAR_RENTAL,
      couponInfo: carVendorCoupons.MW,
      locKey: midwayCarRentalCompany,
      includeInDropdown: true,
    },
    ZL: {
      vendorCode: CarsVendorCodes.NATIONAL_CAR_RENTAL,
      couponInfo: carVendorCoupons.ZL,
      locKey: nationalCarRentalCompany,
      includeInDropdown: true,
    },
    NU: {
      vendorCode: CarsVendorCodes.NU_CAR,
      couponInfo: carVendorCoupons.NU,
      locKey: nuCarCompany,
      includeInDropdown: true,
    },
    ZA: {
      vendorCode: CarsVendorCodes.PAYLESS,
      couponInfo: carVendorCoupons.ZA,
      locKey: paylessCompany,
      includeInDropdown: true,
    },
    RO: {
      vendorCode: CarsVendorCodes.ROUTES_CAR_RENTAL,
      couponInfo: carVendorCoupons.RO,
      locKey: routesCarRental,
      includeInDropdown: true,
    },
    SX: {
      vendorCode: CarsVendorCodes.SIXT_CAR_RENTAL,
      couponInfo: carVendorCoupons.SX,
      locKey: sixtCompany,
      includeInDropdown: true,
    },
    ZT: {
      vendorCode: CarsVendorCodes.THRIFTY_CAR_RENTAL,
      couponInfo: carVendorCoupons.ZT,
      locKey: thriftyCarRentalCompany,
      includeInDropdown: true,
    },
    SV: {
      vendorCode: CarsVendorCodes.U_SAVE,
      couponInfo: carVendorCoupons.SV,
      locKey: uSaveCompany,
      includeInDropdown: true,
    },
    SC: {
      vendorCode: CarsVendorCodes.SILVERCAR,
      couponInfo: carVendorCoupons.SC,
      locKey: silvercar,
      includeInDropdown: true,
    },
    GI: {
      vendorCode: CarsVendorCodes.GREEN_MOTION,
      couponInfo: carVendorCoupons.GI,
      locKey: greenMotionCompany,
      includeInDropdown: true,
    },
    NC: {
      vendorCode: CarsVendorCodes.NEXT_CAR,
      couponInfo: carVendorCoupons.NC,
      locKey: nextCarCompany,
      includeInDropdown: true,
    },
    RW: {
      vendorCode: CarsVendorCodes.RENT_A_WRECK,
      couponInfo: carVendorCoupons.RW,
      locKey: rentAWreckCompany,
      includeInDropdown: true,
    },
    PR: {
      vendorCode: CarsVendorCodes.PRICELESS,
      couponInfo: carVendorCoupons.PR,
      locKey: pricelessCompany,
      includeInDropdown: true,
    },
  },
  elementId: "wizard-car-pwa",
  gtConfig: {
    ...gtConfig,
    travelers: {
      ...travelersConfig,
      childrenCaptionToken: "wizard.roomPicker.gt.children.caption",
      infantsCaptionToken: "wizard.roomPicker.gt.infants.caption",
      adultsCaptionToken: "wizard.roomPicker.gt.adults.caption",
      withAges: false,
    },
  },
  travelers: { ...travelersConfig },
  includeGT: true,

  cars: {
    all: {
      carCode: CarTypes.ALL,
      locKey: carType,
      includeInDropdown: true,
    },
    midsize: {
      carCode: CarTypes.MIDSIZE,
      locKey: midsize,
      includeInDropdown: true,
    },
    compact: {
      carCode: CarTypes.COMPACT,
      locKey: compact,
      includeInDropdown: true,
    },
    economy: {
      carCode: CarTypes.ECONOMY,
      locKey: economy,
      includeInDropdown: true,
    },
    fullsize: {
      carCode: CarTypes.FULLSIZE,
      locKey: fullsize,
      includeInDropdown: true,
    },
    luxury: {
      carCode: CarTypes.LUXURY,
      locKey: luxury,
      includeInDropdown: true,
    },
    minivan: {
      carCode: CarTypes.MINIVAN,
      locKey: minivan,
      includeInDropdown: true,
    },
    other: {
      carCode: CarTypes.OTHER,
      locKey: other,
      includeInDropdown: true,
    },
    pickup: {
      carCode: CarTypes.PICKUP,
      locKey: pickup,
      includeInDropdown: true,
    },
    premium: {
      carCode: CarTypes.PREMIUM,
      locKey: premium,
      includeInDropdown: true,
    },
    sportsCar: {
      carCode: CarTypes.SPORTS_CAR,
      locKey: sportsCar,
      includeInDropdown: true,
    },
    standard: {
      carCode: CarTypes.STANDARD,
      locKey: standard,
      includeInDropdown: true,
    },
    SUV: {
      carCode: CarTypes.SUV,
      locKey: SUV,
      includeInDropdown: true,
    },
    van: {
      carCode: CarTypes.VAN,
      locKey: van,
      includeInDropdown: true,
    },
  },
  validations: {
    startTime: false,
    endTime: false,
  },
  carType: "",
  carVendorCode: "",
  carVendorName: "",
  carDiscountTypeCode: "",
  carDiscountTypeName: "",
  carDiscountCode: "",
  showCarDiscount: false,
  isBiasedWizardEmpty: true,
};
