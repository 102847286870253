import { SiteIdOverrideConfig } from "stores/wizard/config";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const siteId11_nl_NL: SiteIdOverrideConfig = {
  thirdPartyPackage: {
    durations: [
      {
        value: "any",
        text: "Beliebig",
      },
      {
        value: "exact",
        text: "wie angegeben",
      },
      {
        value: "7",
        text: "1 Woche",
      },
      {
        value: "14",
        text: "2 Wochen",
      },
      {
        value: "21",
        text: "3 Wochen",
      },
      {
        value: "1,4",
        text: "1-4 Tage",
      },
      {
        value: "5,8",
        text: "5-8 Tage",
      },
      {
        value: "9,12",
        text: "9-12 Tage",
      },
      {
        value: "13,15",
        text: "13-15 Tage",
      },
      {
        value: "16,22",
        text: "16-22 Tage",
      },
      {
        value: "1",
        text: "1 Tag",
      },
      {
        value: "2",
        text: "2 Tage",
      },
      {
        value: "3",
        text: "3 Tage",
      },
      {
        value: "4",
        text: "4 Tage",
      },
      {
        value: "5",
        text: "5 Tage",
      },
      {
        value: "6",
        text: "6 Tage",
      },
      {
        value: "8",
        text: "8 Tage",
      },
      {
        value: "9",
        text: "9 Tage",
      },
      {
        value: "10",
        text: "10 Tage",
      },
      {
        value: "11",
        text: "11 Tage",
      },
      {
        value: "12",
        text: "12 Tage",
      },
      {
        value: "13",
        text: "13 Tage",
      },
      {
        value: "15",
        text: "15 Tage",
      },
      {
        value: "16",
        text: "16 Tage",
      },
      {
        value: "17",
        text: "17 Tage",
      },
      {
        value: "18",
        text: "18 Tage",
      },
      {
        value: "19",
        text: "19 Tage",
      },
      {
        value: "20",
        text: "20 Tage",
      },
      {
        value: "21",
        text: "21 Tage",
      },
      {
        value: "22",
        text: "22 Tage",
      },
      {
        value: "23,100",
        text: "> 22 Tage",
      },
    ],
  },
};
