import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId1070901: SiteIdOverrideConfig = {
  global: {
    heroImageSrc: "https://forever.travel-assets.com/flex/flexmanager/images/2020/08/13/V1_1400x600_Colorado-1.jpg",
  },
  car: {
    date: {
      maxDaysRange: 329,
    },
    timeFormats: {
      timeFormat12Hour: true,
    },
    AARPCheckbox: false,
    includeGT: false,
    gtConfig: {
      location: {
        destination: {
          labelToken: "wizard.gt.propertyName.label",
        },
      },
    },
  },
  hotel: {
    useTravelAgentSearchWizardConfig: true,
    packageRatesTermsUrl: "/static/default/default/taap/contracts/hpr/US.HPR.012021.pdf",
    navigation: {
      tabTitleToken: "wizard.properties.tab.title.label",
    },
    heading: {
      hideLobTitle: true,
      locTitleToken: "wizard.taap.hotel.tab.title.label",
    },
  },
  cruise: {
    heading: {
      lobSubtitleBreakPoint: "on",
      visibleLobSubtitle: true,
      locSubtitleVariant: "1-800-916-8586",
    },
  },
  lx: {
    heading: {
      visibleLobSubtitle: false,
      locSubtitleLinkUrl: "https://www.expedia.com/event-tickets/",
    },
    navigation: {
      tabTitleToken: "wizard.activities.tab.title.label",
    },
    location: {
      destination: {
        labelToken: "wizard.activitiesIn.thingsToDoIn.label",
      },
    },
  },
  package: {
    lobLabels: {
      hotelsLabel: "wizard.properties.tab.title.label",
    },
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.taap.text",
    },
  },
};
