import { SiteIdOverrideConfig } from "stores/wizard/config";

export const defaultOriginsSiteId100388 = [
  {
    code: "GRZ",
    name: "Graz",
  },
  {
    code: "INN",
    name: "Innsbruck",
  },
  {
    code: "KLU",
    name: "Klagenfurt",
  },
  {
    code: "LNZ",
    name: "Linz",
  },
  {
    code: "SZG",
    name: "Salzburg",
  },
  {
    code: "VIE",
    name: "Wien",
  },
  {
    code: "BSL",
    name: "Basel-Mulhouse (CH)",
  },
  {
    code: "BRN",
    name: "Bern-Belp (CH)",
  },
  {
    code: "ZRH",
    name: "Zürich (CH)",
  },
  {
    code: "5000",
    name: "Deutschland Nord",
  },
  {
    code: "5001",
    name: "Deutschland Süd",
  },
  {
    code: "5002",
    name: "Deutschland West",
  },
  {
    code: "5003",
    name: "Deutschland Ost",
  },
  {
    code: "AGB",
    name: "Augsburg (D)",
  },
  {
    code: "NUE",
    name: "Nürnberg (D)",
  },
  {
    code: "STR",
    name: "Stuttgart (D)",
  },
  {
    code: "FDH",
    name: "Friedrichshafen (D)",
  },
  {
    code: "MUC",
    name: "München (D)",
  },
];

export const defaultDestinationsSiteId100388 = [
  {
    code: "40000",
    name: "Top Badereisen",
  },
  {
    code: "40030",
    name: "Afrika",
  },
  {
    code: "40005",
    name: "Ägypten",
  },
  {
    code: "40022",
    name: "Asien",
  },
  {
    code: "40002",
    name: "Balearen",
  },
  {
    code: "40020",
    name: "Bali & Indonesien",
  },
  {
    code: "40042",
    name: "Benelux",
  },
  {
    code: "40017",
    name: "Bulgarien",
  },
  {
    code: "40038",
    name: "Deutschland",
  },
  {
    code: "40018",
    name: "Dom. Republik",
  },
  {
    code: "40009",
    name: "Dubai & Arabische Halbinse",
  },
  {
    code: "40041",
    name: "Frankreich",
  },
  {
    code: "40061",
    name: "Fuerteventura",
  },
  {
    code: "40062",
    name: "Gran Canaria",
  },
  {
    code: "40007",
    name: "Griechenland Festland",
  },
  {
    code: "40006",
    name: "Griechische Inseln",
  },
  {
    code: "40043",
    name: "Großbritannien & Irland",
  },
  {
    code: "40063",
    name: "Ibiza",
  },
  {
    code: "40025",
    name: "Israel & Naher Osten",
  },
  {
    code: "40015",
    name: "Italien",
  },
  {
    code: "40032",
    name: "Jamaika",
  },
  {
    code: "40035",
    name: "Kanada",
  },
  {
    code: "40003",
    name: "Kanarische Inseln",
  },
  {
    code: "40027",
    name: "Kapverdische Inseln",
  },
  {
    code: "40033",
    name: "Karibik",
  },
  {
    code: "40026",
    name: "Kenia",
  },
  {
    code: "40064",
    name: "Korfu & Paxi",
  },
  {
    code: "40065",
    name: "Kreta",
  },
  {
    code: "40016",
    name: "Kroatien",
  },
  {
    code: "40055",
    name: "Lanzarote",
  },
  {
    code: "40057",
    name: "Madeira",
  },
  {
    code: "40023",
    name: "Malediven",
  },
  {
    code: "40056",
    name: "Mallorca",
  },
  {
    code: "40013",
    name: "Malta",
  },
  {
    code: "40010",
    name: "Marokko",
  },
  {
    code: "40024",
    name: "Mauritius & Seychellen",
  },
  {
    code: "40031",
    name: "Mexiko",
  },
  {
    code: "40036",
    name: "Mittelamerika",
  },
  {
    code: "40029",
    name: "Namibia",
  },
  {
    code: "40044",
    name: "Nordeuropa",
  },
  {
    code: "40039",
    name: "Österreich",
  },
  {
    code: "40045",
    name: "Osteuropa",
  },
  {
    code: "40012",
    name: "Portugal & Madeira",
  },
  {
    code: "40040",
    name: "Schweiz",
  },
  {
    code: "40011",
    name: "Spanisches Festland",
  },
  {
    code: "40021",
    name: "Sri Lanka",
  },
  {
    code: "40028",
    name: "Südafrika",
  },
  {
    code: "40037",
    name: "Südamerika",
  },
  {
    code: "40058",
    name: "Teneriffa",
  },
  {
    code: "40019",
    name: "Thailand",
  },
  {
    code: "40008",
    name: "Tunesien",
  },
  {
    code: "40004",
    name: "Türkei",
  },
  {
    code: "40034",
    name: "USA",
  },
  {
    code: "40014",
    name: "Zypern",
  },
];

export const siteId100388: SiteIdOverrideConfig = {
  package: {
    navigation: {
      tabTitleToken: "wizard.packages.tab.label.variant.two",
    },
  },
  thirdPartyPackage: {
    defaultPackageForm: "all-inclusive",
    duration: {
      queryParam: "dur",
    },
    date: {
      queryParam: {
        start: "ddate",
        end: "rdate",
      },
    },
    form: {
      action: "//booking.expedia.at",
    },
    origins: defaultOriginsSiteId100388,
    destinations: defaultDestinationsSiteId100388,
    durations: [
      {
        value: "any",
        text: "any",
      },
      {
        value: "1,5",
        text: "1-5 days",
      },
      {
        value: "5,8",
        text: "5-8 days",
      },
      {
        value: "7",
        text: "1 week",
      },
      {
        value: "9,12",
        text: "9-12 days",
      },
      {
        value: "14",
        text: "2 weeks",
      },
      {
        value: "13,16",
        text: "13-16 days",
      },
      {
        value: "17,22",
        text: "17-22 days",
      },
      {
        value: "23,100",
        text: "> 22 days",
      },
    ],
  },
};
