import { SiteIdOverrideConfig } from "stores/wizard/config";
import { defaultLocationValue_de } from "../../staticConfigs/thirdPartyPackage/thirdPartyPackageConfig";
import { defaultOriginsSiteId10122006, defaultDestinationsSiteId10122006 } from "./siteId10122006";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const siteId10122006_de_DE: SiteIdOverrideConfig = {
  thirdPartyPackage: {
    date: {
      start: {
        labelToken: "wizard.thirdPartyPackage.germanDate.start.label",
      },
      end: {
        labelToken: "wizard.thirdPartyPackage.germanDate.end.label",
      },
    },
    origins: [defaultLocationValue_de, ...defaultOriginsSiteId10122006],
    destinations: [defaultLocationValue_de, ...defaultDestinationsSiteId10122006],
    durations: [
      {
        value: "any",
        text: "Beliebig",
      },
      {
        value: "1,5",
        text: "1-5 Tage",
      },
      {
        value: "5,8",
        text: "5-8 Tage",
      },
      {
        value: "7",
        text: "1 Woche",
      },
      {
        value: "9,12",
        text: "9-12 Tage",
      },
      {
        value: "14",
        text: "2 Wochen",
      },
      {
        value: "13,16",
        text: "13-16 Tage",
      },
      {
        value: "17,22",
        text: "17-22 Tage",
      },
      {
        value: "23,100",
        text: "> 22 Tage",
      },
    ],
  },
};
