import { SiteIdOverrideConfig } from "stores/wizard/config";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const siteId11_en_GB: SiteIdOverrideConfig = {
  package: {
    navigation: {
      tabTitleToken: "wizard.package.tab.title.variant.1.label",
    },
  },
};
