import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId70301: SiteIdOverrideConfig = {
  global: {
    includeSingleLOBsWithinPackages: false,
  },
  flight: {
    heading: {
      locTitleToken: "wizard.cheaptickets.flights.title",
    },
  },
  package: {
    heading: {
      locTitleToken: "wizard.cheaptickets.packages.title",
    },
    lobLabels: {
      hotelsLabel: "wizard.hotel.tab.title.label",
      hotelsEnhancedLabel: "wizard.packages.staySelected.pill.text",
      hotelsEnhancedVariantLabel: "wizard.packages.stay.pill.text.variant",
    },
    showEnhancedLabels: true,
  },
  hotel: {
    heading: {
      locTitleToken: "wizard.comet.hotels.tab.title",
    },
    navigation: {
      tabTitleToken: "wizard.hotel.tab.title.label",
    },
  },
  lx: {
    heading: {
      locTitleToken: "wizard.cheaptickets.activities.title",
      visibleLobSubtitle: true,
      locSubtitleLinkUrl:
        "https://www.cheaptickets.com/events/?utm_source=cheaptickets_search_bar&utm_medium=event_tickets&utm_campaign=cheaptickets_search_events_tickets_tab",
    },
  },
  car: {
    heading: {
      locTitleToken: "wizard.cheaptickets.cars.title",
    },
    timeFormats: {
      timeFormat12Hour: true,
    },
    date: {
      maxDaysRange: 329,
    },
    AARPCheckbox: true,
  },
};
