import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId100707: SiteIdOverrideConfig = {
  hotel: {
    heading: {
      locTitleToken: "wizard.wotif.hotels.title",
    },
    navigation: {
      tabTitleToken: "wizard.wotif.hotels.tab.label",
    },
  },
};
