import { SiteIdOverrideConfig } from "stores/wizard/config";
import { destinationsAu } from "components/flexComponents/WizardCruisePWA/components/customData/destinationsAu";
import { departurePortsAu } from "components/flexComponents/WizardCruisePWA/components/customData/departurePortsAu";
import { durationsAu } from "components/flexComponents/WizardCruisePWA/components/customData/durationsAu";
import { cruisesLineAu } from "components/flexComponents/WizardCruisePWA/components/customData/cruisesLineAu";
import { dateRangesAu } from "components/flexComponents/WizardCruisePWA/components/customData/dateRangesAu";

export const siteId70125: SiteIdOverrideConfig = {
  global: {
    includeSingleLOBsWithinPackages: false,
  },
  flight: {
    heading: {
      locTitleToken: "wizard.wotif.flights.title",
    },
  },
  package: {
    heading: {
      locTitleToken: "wizard.wotif.packages.title",
    },
    lobLabels: {
      hotelsLabel: "wizard.wotif.hotels.tab.label",
      hotelsEnhancedLabel: "wizard.comet.packages.accommodationSelected.pill.text",
      hotelsEnhancedVariantLabel: "wizard.comet.packages.accommodation.pill.text.variant",
    },
    showEnhancedLabels: true,
  },
  hotel: {
    heading: {
      locTitleToken: "wizard.wotif.hotels.title",
    },
    navigation: {
      tabTitleToken: "wizard.wotif.hotels.tab.label",
    },
  },
  lx: {
    heading: {
      locTitleToken: "wizard.wotif.activities.title",
    },
  },
  car: {
    date: {
      maxDaysRange: 329,
    },
    heading: {
      locTitleToken: "wizard.wotif.cars.title",
    },
    navigation: {
      tabTitleToken: "wizard.wotif.label.Cars",
    },
  },
  cruise: {
    date: {
      start: {
        labelToken: "wizard.cruise3pp.sailingDate.label",
      },
      queryParam: {
        start: "date",
      },
    },
    displayWizard3pp: true,
    wizard3pp: {
      destinations: destinationsAu,
      departurePorts: departurePortsAu,
      durations: durationsAu,
      cruiseLines: cruisesLineAu,
      actionUrl: "https://cruise.wotif.com/Cruise/Search",
      queryParam: {
        duration: "Duration",
        destination: "Destination",
        cruiseLine: "Cruiseline",
        departure: "DeparturePort",
        dateRange: "StartDate",
      },
      dateRanges: dateRangesAu,
    },
    heading: {
      lobSubtitleBreakPoint: "on",
      visibleLobSubtitle: true,
      locSubtitleVariant: "1300 381 719",
    },
  },
};
