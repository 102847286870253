import { SiteIdOverrideConfig } from "stores/wizard/config";
import { departurePortsDe } from "src/components/flexComponents/WizardCruisePWA/components/customData/departurePortsDe";
import { cruisesLineDe } from "src/components/flexComponents/WizardCruisePWA/components/customData/cruisesLineDe";
import { destinationsDe } from "src/components/flexComponents/WizardCruisePWA/components/customData/destinationsDe";
import { durationsDe } from "src/components/flexComponents/WizardCruisePWA/components/customData/durationsDe";
import { dateRangesDe } from "src/components/flexComponents/WizardCruisePWA/components/customData/dateRangesDe";

export const siteId1070906: SiteIdOverrideConfig = {
  global: {
    heroImageSrc: "https://a.travel-assets.com/travel-assets-manager/cmct-2135/EMEA_Autumn_Switzerland_1400x600.jpg",
    packageWith3PP: false,
  },
  hotel: {
    useTravelAgentSearchWizardConfig: true,
    packageRatesTermsUrl: "/static/default/default/taap/contracts/hpr/DE.HPR.012021.pdf",
    navigation: {
      tabTitleToken: "wizard.properties.tab.title.label",
    },
    heading: {
      hideLobTitle: true,
      locTitleToken: "wizard.taap.hotel.tab.title.label",
    },
  },
  package: {
    lobLabels: {
      hotelsLabel: "wizard.properties.tab.title.label",
    },
    navigation: {
      tabTitleToken: "wizard.package.lobTitle.packages.taap.text",
    },
  },
  car: {
    driveAgeCheckbox: true,
    includeGT: false,
    gtConfig: {
      location: {
        destination: {
          labelToken: "wizard.gt.propertyName.label",
        },
      },
    },
  },
  lx: {
    navigation: {
      tabTitleToken: "wizard.activities.tab.title.label",
    },
    location: {
      destination: {
        labelToken: "wizard.activitiesIn.thingsToDoIn.label",
      },
    },
  },
  thirdPartyPackage: {
    defaultPackageForm: "all-inclusive",
    duration: {
      queryParam: "dur",
    },
    offersWithTransfer: {
      isVisible: true,
    },
    form: {
      action: "//pauschalreisen.expedia.de",
    },
    date: {
      queryParam: {
        start: "ddate",
        end: "rdate",
      },
    },
    origins: [
      {
        code: "BRE,ENS,HAM,HAJ,KIL,LBC,FMO",
        name: "Nord",
      },
      {
        code: "AGB,BSL,BRN,FDH,GVA,GRZ,HOQ,INN,FKB,KLU,LNZ,FMM,MUC,NUE,SZG,SXB,STR,VIE,ZRH,ZQW",
        name: "Süd",
      },
      {
        code: "AMS,BRU,DTM,DUS,EIN,FRA,HHN,GRQ,CGN,LGG,LUX,MST,MGL,NRN,PAD,SCN",
        name: "West",
      },
      {
        code: "AOC,BER,TXL,SXF,DRS,ERF,KSF,LEJ,ROS,SZW,WAW",
        name: "Ost",
      },
      {
        code: "BSL",
        name: "Basel-Mulhouse (CH)",
      },
      {
        code: "SXF",
        name: "Berlin-Schönefeld",
      },
      {
        code: "TXL",
        name: "Berlin-Tegel",
      },
      {
        code: "BRE",
        name: "Bremen",
      },
      {
        code: "DTM",
        name: "Dortmund",
      },
      {
        code: "DRS",
        name: "Dresden",
      },
      {
        code: "DUS",
        name: "Düsseldorf",
      },
      {
        code: "ERF",
        name: "Erfurt",
      },
      {
        code: "FRA",
        name: "Frankfurt",
      },
      {
        code: "FDH",
        name: "Friedrichshafen",
      },
      {
        code: "GRZ",
        name: "Graz",
      },
      {
        code: "HAM",
        name: "Hamburg",
      },
      {
        code: "HAJ",
        name: "Hannover",
      },
      {
        code: "INN",
        name: "Innsbruck",
      },
      {
        code: "FKB",
        name: "Karlsruhe",
      },
      {
        code: "KSF",
        name: "Kassel",
      },
      {
        code: "CGN",
        name: "Köln",
      },
      {
        code: "LEJ",
        name: "Leipzig-Halle",
      },
      {
        code: "LNZ",
        name: "Linz",
      },
      {
        code: "FMM",
        name: "Memmingen",
      },
      {
        code: "MUC",
        name: "München",
      },
      {
        code: "FMO",
        name: "Münster-Osnabrück",
      },
      {
        code: "NUE",
        name: "Nürnberg",
      },
      {
        code: "PAD",
        name: "Paderborn",
      },
      {
        code: "RLG",
        name: "Rostock",
      },
      {
        code: "SCN",
        name: "Saarbrücken",
      },
      {
        code: "SZG",
        name: "Salzburg",
      },
      {
        code: "SXB",
        name: "Strasbourg",
      },
      {
        code: "STR",
        name: "Stuttgart",
      },
      {
        code: "VIE",
        name: "Wien",
      },
      {
        code: "ZRH",
        name: "Züric",
      },
      {
        code: "ZQW",
        name: "Zweibrücken",
      },
    ],
    destinations: [
      {
        code: "0",
        name: " Beliebig ",
      },
      {
        code: "0",
        name: " Top Badereisen ",
      },
      {
        code: "30",
        name: " Afrika ",
      },
      {
        code: "5",
        name: " Ägypten & Nilkreuzfahrten ",
      },
      {
        code: "22",
        name: " Asien ",
      },
      {
        code: "2",
        name: " Balearen ",
      },
      {
        code: "20",
        name: " Bali & Indonesien ",
      },
      {
        code: "42",
        name: " Benelux ",
      },
      {
        code: "17",
        name: " Bulgarien ",
      },
      {
        code: "38",
        name: " Deutschland ",
      },
      {
        code: "18",
        name: " Dom. Republik ",
      },
      {
        code: "9",
        name: " Dubai & Arabische Halbinsel ",
      },
      {
        code: "41",
        name: " Frankreich ",
      },
      {
        code: "61",
        name: " Fuerteventura ",
      },
      {
        code: "62",
        name: " Gran Canaria ",
      },
      {
        code: "7",
        name: " Griechenland Festland ",
      },
      {
        code: "6",
        name: " Griechische Inseln ",
      },
      {
        code: "43",
        name: " Großbritannien & Irland ",
      },
      {
        code: "63",
        name: " Ibiza ",
      },
      {
        code: "25",
        name: " Israel & Naher Osten ",
      },
      {
        code: "15",
        name: " Italien ",
      },
      {
        code: "32",
        name: " Jamaika ",
      },
      {
        code: "35",
        name: " Kanada ",
      },
      {
        code: "3",
        name: " Kanarische Inseln ",
      },
      {
        code: "27",
        name: " Kapverdische Inseln ",
      },
      {
        code: "33",
        name: " Karibik ",
      },
      {
        code: "26",
        name: " Kenia ",
      },
      {
        code: "64",
        name: " Korfu & Paxi ",
      },
      {
        code: "65",
        name: " Kreta ",
      },
      {
        code: "46",
        name: " Kreuzfahrten ",
      },
      {
        code: "16",
        name: " Kroatien ",
      },
      {
        code: "55",
        name: " Lanzarote ",
      },
      {
        code: "57",
        name: " Madeira ",
      },
      {
        code: "23",
        name: " Malediven ",
      },
      {
        code: "56",
        name: " Mallorca ",
      },
      {
        code: "13",
        name: " Malta ",
      },
      {
        code: "10",
        name: " Marokko ",
      },
      {
        code: "24",
        name: " Mauritius & Seychellen ",
      },
      {
        code: "31",
        name: " Mexiko ",
      },
      {
        code: "36",
        name: " Mittelamerika ",
      },
      {
        code: "29",
        name: " Namibia ",
      },
      {
        code: "44",
        name: " Nordeuropa ",
      },
      {
        code: "39",
        name: " Österreich ",
      },
      {
        code: "45",
        name: " Osteuropa ",
      },
      {
        code: "12",
        name: " Portugal & Madeira ",
      },
      {
        code: "40006:655",
        name: " Rhodos ",
      },
      {
        code: "47",
        name: " Rundreisen ",
      },
      {
        code: "40",
        name: " Schweiz ",
      },
      {
        code: "11",
        name: " Spanisches Festland ",
      },
      {
        code: "21",
        name: " Sri Lanka ",
      },
      {
        code: "28",
        name: " Südafrika ",
      },
      {
        code: "37",
        name: " Südamerika ",
      },
      {
        code: "58",
        name: " Teneriffa ",
      },
      {
        code: "19",
        name: " Thailand ",
      },
      {
        code: "8",
        name: " Tunesien ",
      },
      {
        code: "4",
        name: " Türkei ",
      },
      {
        code: "60",
        name: " Türkische Ägäis ",
      },
      {
        code: "59",
        name: " Türkische Riviera ",
      },
      {
        code: "34",
        name: " USA ",
      },
      {
        code: "14",
        name: " Zypern ",
      },
    ],
    durations: [
      {
        text: "any",
        value: "any",
      },
      {
        text: "as stated",
        value: "exact",
      },
      {
        text: "1 week",
        value: "7",
      },
      {
        text: "2 Weeks",
        value: "14",
      },
      {
        text: "3 Weeks",
        value: "21",
      },
      {
        text: "1-4 days",
        value: "1,4",
      },
      {
        text: "5-8 days",
        value: "5,8",
      },
      {
        text: "9-12 days",
        value: "9,12",
      },
      {
        text: "13-15 days",
        value: "13,15",
      },
      {
        text: "16-22 days",
        value: "16,22",
      },
      {
        text: "1 day",
        value: "1",
      },
      {
        text: "2 days",
        value: "2",
      },
      {
        text: "3 days",
        value: "3",
      },
      {
        text: "4 days",
        value: "4",
      },
      {
        text: "5 days",
        value: "5",
      },
      {
        text: "6 days",
        value: "6",
      },
      {
        text: "8 days",
        value: "8",
      },
      {
        text: "9 days",
        value: "9",
      },
      {
        text: "10 days",
        value: "10",
      },
      {
        text: "11 days",
        value: "11",
      },
      {
        text: "12 days",
        value: "12",
      },
      {
        text: "13 days",
        value: "13",
      },
      {
        text: "15 days",
        value: "15",
      },
      {
        text: "16 days",
        value: "16",
      },
      {
        text: "17 days",
        value: "17",
      },
      {
        text: "18 days",
        value: "18",
      },
      {
        text: "19 days",
        value: "19",
      },
      {
        text: "20 days",
        value: "20",
      },
      {
        text: "21 days",
        value: "21",
      },
      {
        text: "22 days",
        value: "22",
      },
      {
        text: "> 22 days",
        value: "23,100",
      },
    ],
  },
  cruise: {
    date: {
      start: {
        labelToken: "wizard.cruise3pp.sailingDate.label",
      },
      queryParam: {
        start: "date",
      },
    },
    displayWizard3pp: true,
    wizard3pp: {
      destinations: destinationsDe,
      departurePorts: departurePortsDe,
      durations: durationsDe,
      cruiseLines: cruisesLineDe,
      actionUrl: "https://www.expedia.de/kreuzfahrten/kreuzfahrt-suchen",
      dateRanges: dateRangesDe,
    },
    heading: {
      lobSubtitleBreakPoint: "on",
      visibleLobSubtitle: true,
      locSubtitleVariant: "+069-204 56 803",
    },
  },
};
