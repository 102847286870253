import { SiteIdOverrideConfig } from "stores/wizard/config";
import { isVariantEnabled } from "../../../../../../src/components/utility/experiment";
export const siteId509010011: SiteIdOverrideConfig = {
  car: {
    disableVendorCouponCode: isVariantEnabled(undefined, "Delta_Enable_Discount_Code", 1),
    showVendorOptions: isVariantEnabled(undefined, "Delta_Enable_Discount_Code", 1),
    timeFormats: {
      timeFormat12Hour: true,
    },
    heading: {
      locTitleDesktopTextToken: "wizard.delta.cars.tab.title",
      locTitleToken: "wizard.mobile.delta.cars.tab.title",
      differentiatedTitles: true,
    },
  },
  hotel: {
    payWithPointsCheckedToken: "wizard.delta.hotel.pwpToggle.points",
    payWithCashCheckedToken: "wizard.delta.hotel.pwpToggle.cash",
    heading: {
      locTitleDesktopTextToken: "wizard.delta.hotels.tab.title",
      locTitleToken: "wizard.mobile.delta.hotels.tab.title",
      differentiatedTitles: true,
    },
  },
  vr: {
    navigation: {
      tabTitleToken: "wizard.vr.tab.title.label.custom.capitalization",
    },
    heading: {
      locTitleDesktopTextToken: "wizard.delta.rentals.tab.title",
      differentiatedTitles: true,
    },
    searchButtonAlongsideDates: false,
    payWithPointsCheckedToken: "wizard.delta.hotel.pwpToggle.points",
    payWithCashCheckedToken: "wizard.delta.hotel.pwpToggle.cash",
    vrPropertyTypes: [
      "VACATION_HOME",
      "CONDO",
      "APARTMENT",
      "CABIN",
      "CHALET",
      "CONDO_RESORT",
      "COTTAGE",
      "HOUSE_BOAT",
      "RESIDENCE",
      "VILLA",
      "CARAVAN_PARK",
    ],
  },
};
