import { GlobalConfig } from "stores/wizard/config/staticConfigs/global";
import { LocationState } from "stores/wizard/config/staticConfigs/global/typings";

export const globalConfig: GlobalConfig = {
  navigation: {
    defaultLob: "wizard-hotel-pwa-v2",
    activeLob: "wizard-hotel-pwa-v2",
  },
  date: {
    invalidDateRangeEndBeforeStart: "wizard.datePicker.warning.endDateBeforeStartDate.text",
  },
  packageWith3PP: false,
  heroImageSrc: "",
  defaultDestination: "",
  defaultMultipleOrigin: undefined,
  defaultOrigin: "",
  defaultMultipleDestination: undefined,
  selectedProduct: "",
  halfWizard: false,
  destinationDropdownTypeahead: false,
  calendarFilterCarrierCode: "",
  showHeroMobile: false,
  collapsed: false,
  cobrandImageUrl: "",
  displayBiasedWizard: true,
  includeSingleLOBsWithinPackages: false,
  userLocation: "",
  originType: LocationState.DEFAULT,
  destiantionType: LocationState.DEFAULT,
};
