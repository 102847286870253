import { SiteIdOverrideConfig } from "stores/wizard/config";

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const siteId70472_fr_CH: SiteIdOverrideConfig = {
  thirdPartyPackage: {
    heading: {
      locTitleToken: "wizard.ebookers.3PP.title",
    },
    origins: [
      {
        code: "any",
        name: "indifférent",
      },
      {
        code: "AGB",
        name: "Augsbourg (D)",
      },
      {
        code: "BRN",
        name: "Berne",
      },
      {
        code: "BSL",
        name: "Bâle Euro Airport",
      },
      {
        code: "MLH",
        name: "Bâle-Mulhouse",
      },
      {
        code: "FRA",
        name: "Francfort-sur-le-Main (D)",
      },
      {
        code: "FDH",
        name: "Friedrichshafen (D)",
      },
      {
        code: "GVA",
        name: "Genève",
      },
      {
        code: "GRZ",
        name: "Graz (A)",
      },
      {
        code: "INN",
        name: "Innsbruck (A)",
      },
      {
        code: "FKB",
        name: "Karlsruhe (D)",
      },
      {
        code: "KLU",
        name: "Klagenfurt (A)",
      },
      {
        code: "LNZ",
        name: "Linz (A)",
      },
      {
        code: "LUG",
        name: "Lugano",
      },
      {
        code: "FMM",
        name: "Memmingen (D)",
      },
      {
        code: "MIL",
        name: "Milan (A)",
      },
      {
        code: "MUC",
        name: "Munich (D)",
      },
      {
        code: "NUE",
        name: "Nuremberg (D)",
      },
      {
        code: "ACH",
        name: "Saint-Gallen",
      },
      {
        code: "SZG",
        name: "Salzbourg (A)",
      },
      {
        code: "STR",
        name: "Stuttgart (D)",
      },
      {
        code: "VIE",
        name: "Vienne (A)",
      },
      {
        code: "ZRH",
        name: "Zurich",
      },
    ],
    destinations: [
      {
        code: "any",
        name: "indifférent",
      },
      {
        code: "10011",
        name: "Afrique",
      },
      {
        code: "30032",
        name: "Afrique du Sud",
      },
      {
        code: "10029",
        name: "Allemagne",
      },
      {
        code: "10037",
        name: "Amérique central",
      },
      {
        code: "10023",
        name: "Amérique du Sud",
      },
      {
        code: "10004:434",
        name: "Antalya",
      },
      {
        code: "10017",
        name: "Asie",
      },
      {
        code: "20015",
        name: "Autriche",
      },
      {
        code: "10017:311",
        name: "Bali  & Indonésie",
      },
      {
        code: "20014",
        name: "Benelux",
      },
      {
        code: "10030:20006",
        name: "Bulgarie",
      },
      {
        code: "10024",
        name: "Canada",
      },
      {
        code: "10014",
        name: "Caraibe",
      },
      {
        code: "10019",
        name: "Chypre",
      },
      {
        code: "10033",
        name: "Circuits",
      },
      {
        code: "10005:616",
        name: "Crète",
      },
      {
        code: "10030:20007",
        name: "Croatie, Hongrie",
      },
      {
        code: "10010",
        name: "Égypte",
      },
      {
        code: "10020",
        name: "Emirats Arabes Unis",
      },
      {
        code: "10003",
        name: "Espagne",
      },
      {
        code: "10015",
        name: "États-Unis-Amérique",
      },
      {
        code: "10030:20018",
        name: "Europ de l'Est",
      },
      {
        code: "10028",
        name: "Europe centrale",
      },
      {
        code: "10022",
        name: "Europe du Nord",
      },
      {
        code: "10021",
        name: "France",
      },
      {
        code: "10001:832",
        name: "Fuerteventura",
      },
      {
        code: "10001:345",
        name: "Grande Canarie",
      },
      {
        code: "30023",
        name: "Grande-Bretagne  & Irlande",
      },
      {
        code: "10006",
        name: "Grèce Continent",
      },
      {
        code: "10010:350",
        name: "Hourghada",
      },
      {
        code: "10000",
        name: "Iles Baléares",
      },
      {
        code: "10001",
        name: "Iles Canaries",
      },
      {
        code: "10005",
        name: "Iles Grèce",
      },
      {
        code: "10007",
        name: "Italie, Malte",
      },
      {
        code: "10014:247",
        name: "Jamaïque",
      },
      {
        code: "10001:676",
        name: "Lanzarote",
      },
      {
        code: "10002:639",
        name: "Madère",
      },
      {
        code: "10000:35",
        name: "Majorque",
      },
      {
        code: "10036:342",
        name: "Maldives",
      },
      {
        code: "10036:143",
        name: "Maurice",
      },
      {
        code: "10016",
        name: "Mexique",
      },
      {
        code: "10026",
        name: "Moyen-Orient",
      },
      {
        code: "10011:453",
        name: "Namibie",
      },
      {
        code: "10036",
        name: "Océan Indien",
      },
      {
        code: "10002",
        name: "Portugal",
      },
      {
        code: "10013",
        name: "République Dominicaine",
      },
      {
        code: "10010:351",
        name: "Sharm El Sheikh",
      },
      {
        code: "10017:361",
        name: "Sri Lanka",
      },
      {
        code: "20016",
        name: "Suisse",
      },
      {
        code: "10001:56",
        name: "Tenerife",
      },
      {
        code: "10027",
        name: "Thailande",
      },
      {
        code: "10008",
        name: "Tunisie, Maroc",
      },
      {
        code: "10004",
        name: "Turquie",
      },
    ],
    durations: [
      {
        value: "any",
        text: "indifférent",
      },
      {
        value: "exact",
        text: "comme indiqué",
      },
      {
        value: "7",
        text: "1 semaine",
      },
      {
        value: "14",
        text: "2 semaines",
      },
      {
        value: "21",
        text: "3 semaines",
      },
      {
        value: "1,4",
        text: "1-4 jours",
      },
      {
        value: "5,8",
        text: "5-8 jours",
      },
      {
        value: "9,12",
        text: "9-12 jours",
      },
      {
        value: "13,15",
        text: "13-15 jours",
      },
      {
        value: "16,22",
        text: "16-22 jours",
      },
      {
        value: "1",
        text: "1 jour",
      },
      {
        value: "2",
        text: "2 jours",
      },
      {
        value: "3",
        text: "3 jours",
      },
      {
        value: "4",
        text: "4 jours",
      },
      {
        value: "5",
        text: "5 jours",
      },
      {
        value: "6",
        text: "6 jours",
      },
      {
        value: "8",
        text: "8 jours",
      },
      {
        value: "9",
        text: "9 jours",
      },
      {
        value: "10",
        text: "10 jours",
      },
      {
        value: "11",
        text: "11 jours",
      },
      {
        value: "12",
        text: "12 jours",
      },
      {
        value: "13",
        text: "13 jours",
      },
      {
        value: "15",
        text: "15 jours",
      },
      {
        value: "16",
        text: "16 jours",
      },
      {
        value: "17",
        text: "17 jours",
      },
      {
        value: "18",
        text: "18 jours",
      },
      {
        value: "19",
        text: "19 jours",
      },
      {
        value: "20",
        text: "20 jours",
      },
      {
        value: "21",
        text: "21 jours",
      },
      {
        value: "22",
        text: "22 jours",
      },
      {
        value: "23,100",
        text: "> 22 jours",
      },
    ],
  },
  hotel: {
    heading: {
      locTitleToken: "wizard.ebookers.hotels.tab.title",
    },
  },
  package: {
    heading: {
      locTitleToken: "wizard.ebookers.packages.tab.title",
    },
  },
};
