import * as React from "react";

import { LOBState } from "stores/wizard/state";

export const useConfigOverrides = (wizardLobState: LOBState, callback: () => void) => {
  const overridesCalled = React.useRef(false);
  if (overridesCalled.current) {
    return;
  }

  wizardLobState.overrideConfig(callback);
  overridesCalled.current = true;
};
