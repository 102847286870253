import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId70201: SiteIdOverrideConfig = {
  global: {
    includeSingleLOBsWithinPackages: false,
  },
  flight: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.orbitz.label.flight",
      locSubtitleTextToken: "wizard.orbitz.subLabel.flight",
      differentiatedTitles: true,
    },
  },
  package: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.orbitz.label.packages",
      locSubtitleTextToken: "wizard.orbitz.subLabel.packages",
      differentiatedTitles: true,
    },
    lobLabels: {
      hotelsLabel: "wizard.hotel.tab.title.label",
      hotelsEnhancedLabel: "wizard.packages.staySelected.pill.text",
      hotelsEnhancedVariantLabel: "wizard.packages.stay.pill.text.variant",
    },
    showEnhancedLabels: true,
  },
  hotel: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.comet.hotels.tab.title",
      locSubtitleTextToken: "wizard.orbitz.subLabel.hotel",
      locTitleToken: "wizard.comet.hotels.tab.title",
      differentiatedTitles: true,
    },
    navigation: {
      tabTitleToken: "wizard.hotel.tab.title.label",
    },
  },
  lx: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.orbitz.label.Activities",
      locSubtitleTextToken: "wizard.orbitz.subLabel.Activities",
      differentiatedTitles: true,
    },
  },
  car: {
    date: {
      maxDaysRange: 329,
    },
    timeFormats: {
      timeFormat12Hour: true,
    },
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.orbitz.label.Cars",
      locSubtitleTextToken: "wizard.orbitz.subLabel.Cars",
      differentiatedTitles: true,
    },
    AARPCheckbox: true,
  },
  cruise: {
    heading: {
      lobSubtitleBreakPoint: "medium",
      locTitleDesktopTextToken: "wizard.orbitz.label.Cruises",
      differentiatedTitles: false,
      visibleLobSubtitle: true,
      locSubtitleVariant: "1-844-730-9892",
    },
  },
};
