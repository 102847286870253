import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId70801: SiteIdOverrideConfig = {
  car: {
    includeGT: false,
    timeFormats: {
      timeFormat12Hour: true,
    },
    AARPCheckbox: true,
    date: {
      maxDaysRange: 329,
    },
  },
};
